import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { translate } from 'react-admin';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardIcon from './CardIcon';

import styles from '../../styles/dashboardStyles';

class DashboardCard extends React.Component {
    render() {
        const { item, translate } = this.props;
        return (
            <Card key={item.name} style={{backgroundColor: item.color,...styles.card}} onClick={() => {window.location = '#/' + item.name;}}>
                <CardIcon Icon={item.icon} />
                <Typography component='p' style={styles.title}>
                    {translate(`resources.${item.name}.name`, {
                        smart_count: 2,
                    })}
                </Typography>
            </Card>
        );
    }
}

DashboardCard.propTypes = {
    item: PropTypes.object,
    translate: PropTypes.func
};

const enhance = compose(
    translate,
);

export default enhance(DashboardCard);