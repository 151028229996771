import React from 'react';
import { Filter, TextInput, NullableBooleanInput,} from 'react-admin';

import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';
import Zones from '../Zones';

const ListFilters = ({permissions, ...props}) => (
    <Filter {...props}>
        <TextInput
            source="DistrictName_like"
            label="resources.districts.fields.DistrictName"
            alwaysOn
            resettable
        />
        {MultiDistricts.roles.includes(permissions) && <TextInput
            source="MultiDistrictName_like"
            label="resources.districts.fields.MultiDistrictName"
            resettable
        />}

        {Partners.roles.includes(permissions) && <TextInput
            source="PartnerName_like"
            label="resources.districts.fields.PartnerName"
            resettable
        />}

        {Zones.roles.includes(permissions) && <TextInput
            source="ZoneName_like"
            label="resources.districts.fields.ZoneName"
            resettable
        />}

        <NullableBooleanInput
            source="Active"
            alwaysOn
        />
    </Filter>
);

export default ListFilters;