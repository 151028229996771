import React from 'react';
import { BooleanField } from 'react-admin';

import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

const styles = {
    true: { color: green[800], 'fontWeight': 800 },
    false: { color: grey[400] }
};

const colored = WrappedComponent => {
    const component = (props) =>
        (props.record[props.source]) ? (
            <WrappedComponent {...props} style={styles.true} />
        ) : (
            <WrappedComponent {...props} style={styles.false} />
        );

    component.displayName = `Colored(${WrappedComponent.displayName})`;
    return component;
};

const ColoredBooleanField = colored(BooleanField);

// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredBooleanField.defaultProps = BooleanField.defaultProps;

export default ColoredBooleanField;