import React from 'react';
import { translate } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import DashboardCard from './DashboardCard';

import Courses from '../../pages/Courses';
import Countries from '../../pages/Countries';
import Roles from '../../pages/Roles';
import States from '../../pages/States';
import Users from '../../pages/Users';

import styles from '../../styles/dashboardStyles';

import deepPurple from '@material-ui/core/colors/deepPurple';
import blue from '@material-ui/core/colors/blue';
import cyan from '@material-ui/core/colors/cyan';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';

const colorIndex = 'A700';

const items = [
    {name: Courses.name, icon: Courses.Icon, color: deepPurple[colorIndex], roles: Courses.roles},
    {name: States.name, icon: States.Icon, color: blue[colorIndex], roles: States.roles},
    {name: Countries.name, icon: Countries.Icon, color: cyan[colorIndex], roles: Countries.roles},
    {name: Users.name, icon: Users.Icon, color: green[colorIndex], roles: Users.roles},
    {name: Roles.name, icon: Roles.Icon, color: orange[colorIndex], roles: Roles.roles},
];

const GroupCard = translate(({ translate, permissions }) => (
    <Paper style={ styles.paper }>
        <Typography variant='title'>
            { translate('nayen.dashboard.groups.sysadmin') }
        </Typography>
        {items.map(item => {
            return item.roles.includes(permissions) && <DashboardCard key={item.name} item={item} />;
        })}
    </Paper>
));

export default GroupCard;