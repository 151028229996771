import buildApolloClient from './gql-data-provider';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';

export default () => {
    const getGqlResource = resource => {
        switch (resource) {
        case 'clubs':
            return 'Viewclub';
        case 'countries':
            return 'Country';
        case 'courses':
            return 'Classtype';
        case 'districts':
            return 'Viewdistrict';
        case 'districtcourses':
            return 'Viewdistrictclass';
        case 'members':
            return 'Viewmember';
        case 'membertrainingrequests':
            return 'Membertrainingrequest';
        case 'multidistricts':
            return 'Multidistrict';
        case 'partners':
            return 'Partner';
        case 'partnercourses':
            return 'Viewpartnerclass';
        case 'roles':
            return 'Securityrole';
        case 'states':
            return 'Viewstate';
        case 'trainings':
            return 'Viewtrainingrequest';
        case 'users':
            return 'Viewuser';
        case 'userroles':
            return 'Userrole';
        case 'viewmembercourses':
            return 'Viewmemberclass';
        case 'viewmembertrainingrequests':
            return 'Viewmembertrainingrequest';
        case 'viewmembertrainingresults':
            return 'Viewmembertrainingresult';
        case 'zones':
            return 'Zone';
        default:
            throw new Error(`Unknown resource ${resource}`);
        }
    };

    const getResource = (resource, type) => {
        let newResource = getGqlResource(resource);
        const viewResources = [
            'clubs',
            'districts',
            'districtcourses',
            'members',
            'partnercourses',
            'states',
            'users',
        ];
        if (newResource && viewResources.includes(resource) && ((type === 'UPDATE') || (type === 'CREATE') || (type === 'DELETE'))) {
            newResource = newResource.substr(4, 1).toUpperCase() + newResource.substr(5);
        }
        return newResource;
    };

    const getParams = (resource, type, params) => {
        let newParams = params;
        // remove extra params from certain forms
        if (type === 'CREATE') {
            switch (resource) {
            case 'partnercourses':
                newParams.data = {
                    PartnerID: params.data.PartnerID,
                    ClassTypeID: params.data.ClassTypeID
                };
                break;
            case 'districtcourses':
                newParams.data = {
                    DistrictID: params.data.DistrictID,
                    ClassTypeID: params.data.ClassTypeID
                };
                break;
            default:
                break;
            }
        }
        return newParams;
    };

    const token = localStorage.getItem('auth_token');
    /*eslint no-undef: "off"*/
    const httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL , fetch });
    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            }
        };
    });

    return buildApolloClient({
        clientOptions: {
            link: authLink.concat(httpLink),
        }
    }).then(dataProvider => (type, resource, params) => {
        // get new resource based on requested resource and the type of query
        let newResource = getResource(resource, type);

        let newParams = getParams(resource, type, params);

        return dataProvider(type, newResource, newParams);
    });
};