import React from 'react';
import { Filter, TextInput, NullableBooleanInput,} from 'react-admin';

import Districts from '../Districts';
import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';

const ListFilters = ({permissions, ...props}) => (
    <Filter {...props}>
        <TextInput
            source="ClubName_like"
            label="resources.clubs.fields.ClubName"
            alwaysOn
            resettable
        />

        {Districts.roles.includes(permissions) && <TextInput
            source="DistrictName_like"
            label="resources.clubs.fields.DistrictName"
            resettable
        />}

        {MultiDistricts.roles.includes(permissions) && <TextInput
            source="MultiDistrictName_like"
            label="resources.clubs.fields.MultiDistrictName"
            resettable
        />}

        {Partners.roles.includes(permissions) && <TextInput
            source="PartnerName_like"
            label="resources.clubs.fields.PartnerName"
            resettable
        />}
        <TextInput
            source="StateCode_like"
            label="resources.clubs.fields.StateName"
            resettable
        />
        <TextInput
            source="CountryCode_like"
            label="resources.clubs.fields.CountryName"
            resettable
        />

        <NullableBooleanInput
            source="Active"
            label="resources.clubs.fields.Active"
            alwaysOn
        />
    </Filter>
);

export default ListFilters;