const gqlFetch = (json) => {
    const token = localStorage.getItem('auth_token');
    const request = new Request(process.env.REACT_APP_GRAPHQL_URL, {
        method: 'POST',
        body: JSON.stringify(json),
        headers: new Headers({
            'Content-Type': 'application/json',
            authorization: token ? `Bearer ${token}` : '',
        }),
    });

    return fetch(request);

}
export default gqlFetch;