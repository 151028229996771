import React from 'react';
import {
    List, Datagrid,
    TextField, DateField
} from 'react-admin';
import ListActions from '../../components/actions/ListActions';
import { ColoredBooleanField } from '../../components/fields';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/listStyles';

const CountriesList = withStyles(styles)(({classes, ...props}) => (
    <List
        actions={<ListActions />}
        perPage={ 25 }
        bulkActionButtons={ false }
        sort={{ field: 'CountryName', order: 'ASC' }}
        filter={{ Active: true }}
        { ...props }
    >
        <Datagrid
            rowClick='show'
        >
            <TextField
                source='CountryName'
            />
            <TextField
                source='CountryCode'
                headerClassName={classes.countryCode}
                cellClassName={classes.countryCode}
            />
            <ColoredBooleanField
                source='Active'
                headerClassName={classes.active}
                cellClassName={classes.active}
            />
            <DateField
                source='CreateDate'
                headerClassName={classes.createDate}
                cellClassName={classes.createDate}
            />
            <DateField
                source='ModifiedDate'
                headerClassName={classes.modifiedDate}
                cellClassName={classes.modifiedDate}
            />
        </Datagrid>
    </List>
));
export default CountriesList;