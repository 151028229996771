const styles = (theme) => ({
    email: {
        minWidth: '120px',
        maxWidth: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },

    firstName: {
        '& span': {
            minWidth: '100px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },

    groupField: {
        '& div': {
            padding: 0,
        },
    },

    inlineBlock: {
        display: 'inline-flex',
        marginRight: '2em',
    },

    lastName: {
        '& span': {
            minWidth: '100px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },

    listButton: {
        minWidth: 0,
        padding: 0,
        '& span': {
            '& span': {
                display: 'none',
            },
        },
    },

    multiDistrictName: {
        '& span': {
            minWidth: '90px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },

    partnerName: {
        '& span': {
            minWidth: '90px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },

    requestDate: {
        minWidth: '150px',
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },

    score: {
        float: 'right',
        margin: '10px',
        '& div': {
            borderRadius: '56px',
            fontSize: '1.85em',
            height: '56px',
            width: '56px',
        },
    },

    scoreList: {
        '& div': {
            borderRadius: '28px',
            fontSize: '0.925em',
            height: '28px',
            width: '28px',
        },
    },

    statusCode: {
        minWidth: '80px',
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },

    trainedDate: {
        minWidth: '150px',
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },

});

export default styles;