import React from 'react';
import {
    Create, SimpleForm,
    TextInput, BooleanInput, DateInput, ReferenceInput, AutocompleteInput,
} from 'react-admin';
//import { DateInput } from 'react-admin-date-inputs';

import { required } from '../../util/fieldValidation';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const DistrictsCreate = withStyles(styles)(({ classes,...props }) => (
    <Create {...props}>
        <SimpleForm submitOnEnter={false} >
            <TextInput
                source='DistrictName'
                validate={[required()]}
            />

            <ReferenceInput
                source='MultiDistrictID'
                reference='multidistricts'
                validate={[required()]}
                sort={{ field: 'MultiDistrictName', order: 'ASC' }}
                perPage={1000}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ MultiDistrictName_like: searchText })}
                formClassName={classes.inlineBlock}
            >
                <AutocompleteInput optionText='MultiDistrictName' />
            </ReferenceInput>

            <ReferenceInput
                source='PartnerID'
                reference='partners'
                validate={[required()]}
                sort={{ field: 'PartnerName', order: 'ASC' }}
                perPage={1000}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ PartnerName_like: searchText })}
                formClassName={classes.inlineBlock}
            >
                <AutocompleteInput optionText='PartnerName' />
            </ReferenceInput>

            <ReferenceInput
                source='ZoneID'
                reference='zones'
                validate={[required()]}
                sort={{ field: 'ZoneName', order: 'ASC' }}
                perPage={1000}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ ZoneName_like: searchText })}
            >
                <AutocompleteInput optionText='ZoneName' />
            </ReferenceInput>

            <DateInput
                source='Expires'
                options={{
                    format: 'MM/DD/YYYY',
                    //clearable: true,
                    //keyboard: true
                }}
            />

            <BooleanInput
                source='Active'
                defaultValue={true}
            />
        </SimpleForm>
    </Create>
));
export default DistrictsCreate;