import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

import defaultTheme from './defaultTheme';

// https://material-ui.com/customization/default-theme/
const theme = createMuiTheme({
    palette: {
        primary: {
            main: blue[700],
        },

        secondary: {
            contrastText: defaultTheme.palette.common.white,
            main: blue[900],
        },
    },

    typography: {
        useNextVariants: true,
        title: {
            fontSize: '1.2rem',
            fontWeight: 600,
        },
        headline: {
            color: blue[900],
            fontSize: '1.2rem',
            fontWeight: 600,
        }
    },

    overrides: {
        MuiFormLabel: {
            root: {
                color: grey[700],
                fontWeight: 600,
            },
        },
        MuiInputLabel: {
            shrink: {
                fontSize: '1.25rem',
            },
        },

        MuiButton: {
            root: {
                fontWeight: 600,
            }
        },

        MuiTableHead: {
            root: {
                backgroundColor: grey[200],
            }
        },
        MuiTableRow: {
            root: {
                height: '40px',
            },
            head: {
                height: '40px',
            },
        },
        MuiTableCell: {
            root: {
                padding: '0 8px !important'
            },
            head: {
                color: grey[700],
                fontSize: '0.95rem',
                fontWeight: 600,
            },
        },

        MuiDrawer: {
            docked: {
                backgroundColor: blue[50],
                borderRight: grey[300] + ' 2px solid',
                height: 'calc(100vh - 50px)',
                marginRight: '0.5em',
                overflowY: 'auto',
            },
        },

        MuiMenuItem: {
            root: {
                color: grey[800] + ' !important',
            },
        },

        MuiSwitch: {
            bar: {
                backgroundColor: green[400] + ' !important',
            },

            checked: {
                color: green[600] + ' !important',
            },
        },

    }
});

export default theme;