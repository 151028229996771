import React from 'react';
import {
    Edit, SimpleForm,
    TextInput, BooleanInput, DateField,
} from 'react-admin';
import Toolbar from '../../components/toolbars/EditToolbar';
import Title from './Title';

import { required } from '../../util/fieldValidation';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const MultiDistrictsEdit = withStyles(styles)(({ classes,...props }) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<Toolbar />} submitOnEnter={false} >
            <TextInput
                source='MultiDistrictName'
                validate={[required()]}
            />
            <BooleanInput
                source='Active'
            />
            <DateField
                source='CreateDate'
                showTime
                formClassName={classes.inlineBlock}
            />
            <DateField
                source='ModifiedDate'
                showTime
                formClassName={classes.inlineBlock}
            />
        </SimpleForm>
    </Edit>
));
export default MultiDistrictsEdit;