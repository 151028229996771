import React from 'react';
import {
    Show, TabbedShowLayout, Tab, Datagrid,
    TextField, EmailField, DateField, ReferenceField,
    ReferenceManyField, Pagination,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import styles from '../../styles/showStyles';

import {
    GroupField, ColoredBooleanField,
    ColoredStatusCodeField, ColoredScoreField, LinkField,
} from '../../components/fields';
import {
    SendTrainingRequestButton, AssignRoleButton
} from '../../components/buttons';
import Title from './Title';

import Clubs from '../Clubs';
import Districts from '../Districts';
import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';
import Users from '../Users';
import Zones from '../Zones';
import States from '../States';
import Countries from '../Countries';

const ShowPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const MembersShow = withStyles(styles)(({ permissions, classes,...props }) => {
    return (
        <Show title={<Title />} {...props}>
            <TabbedShowLayout>
                <Tab
                    label='resources.members.tabs.details'
                >
                    <SendTrainingRequestButton />
                    <EmailField
                        source='Email'
                    />

                    <GroupField>
                        {Clubs.roles.includes(permissions) ? <LinkField
                            source='ClubID'
                            reference='clubs'
                            addLabel={true}
                            className={classes.inlineBlock}
                        >
                            <TextField source='ClubName' />
                        </LinkField> : <TextField source='ClubName' className={classes.inlineBlock} /> }

                        {States.roles.includes(permissions) ? <LinkField
                            source='StateID'
                            reference='states'
                            addLabel={true}
                            className={classes.inlineBlock}
                        >
                            <TextField source='StateName' />
                        </LinkField> : <TextField source='StateName' className={classes.inlineBlock} />}

                        {Countries.roles.includes(permissions) ? <LinkField
                            source='CountryID'
                            reference='countries'
                            addLabel={true}
                            className={classes.inlineBlock}
                        >
                            <TextField source='CountryName' />
                        </LinkField> : <TextField source='CountryName' className={classes.inlineBlock} />}


                    </GroupField>

                    <GroupField>
                        {Districts.roles.includes(permissions) ? <LinkField
                            source='DistrictID'
                            reference='districts'
                            addLabel={true}
                            className={classes.inlineBlock}
                        >
                            <TextField source='DistrictName' />
                        </LinkField> : <TextField source='DistrictName' className={classes.inlineBlock} />}

                        {MultiDistricts.roles.includes(permissions) ? <LinkField
                            source='MultiDistrictID'
                            reference='multidistricts'
                            addLabel={true}
                            className={classes.inlineBlock}
                        >
                            <TextField source='MultiDistrictName' />
                        </LinkField> : <TextField source='MultiDistrictName' className={classes.inlineBlock} />}

                        {Partners.roles.includes(permissions) ? <LinkField
                            source='PartnerID'
                            reference='partners'
                            addLabel={true}
                            className={classes.inlineBlock}
                        >
                            <TextField source='PartnerName' className={classes.inlineBlock} />
                        </LinkField> : <TextField source='PartnerName' />}
                    </GroupField>

                    {Zones.roles.includes(permissions) ? <LinkField
                        source='ZoneID'
                        reference='zones'
                        addLabel={true}
                    >
                        <TextField source='ZoneName' />
                    </LinkField> : <TextField source='ZoneName' />}

                    <GroupField>
                        <TextField
                            source='id'
                            className={classes.inlineBlock}
                        />
                        <TextField
                            source='ExternalID'
                            className={classes.inlineBlock}
                        />
                    </GroupField>

                    <ColoredBooleanField
                        source='Active'
                    />

                    <GroupField>
                        <DateField
                            source='CreateDate'
                            showTime
                            className={classes.inlineBlock}
                        />
                        <DateField
                            source='ModifiedDate'
                            showTime
                            className={classes.inlineBlock}
                        />
                    </GroupField>
                </Tab>
                <Tab
                    label='resources.members.tabs.trainingresults'
                    path='trainingresults'
                >
                    <ReferenceManyField
                        reference='viewmembertrainingresults'
                        target='MemberID'
                        sort={{ field: 'TrainedDt', order: 'DESC' }}
                        perPage={25}
                        addLabel={false}
                        pagination={<ShowPagination />}
                    >
                        <Datagrid
                            rowClick={(id)=>'/trainings/' + id + '/show'}
                        >
                            <TextField
                                source='id'
                            />
                            <TextField
                                source='ClassName'
                            />
                            <ColoredScoreField
                                source='Score'
                                cellClassName={classes.scoreList}
                            />
                            <DateField
                                source='TrainedDt'
                                headerClassName={classes.trainedDate}
                                cellClassName={classes.trainedDate}
                                showTime
                            />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab
                    label='resources.members.tabs.trainingrequests'
                    path='trainingrequests'
                >
                    <ReferenceManyField
                        reference='viewmembertrainingrequests'
                        target='MemberID'
                        sort={{ field: 'RequestDate', order: 'DESC' }}
                        perPage={25}
                        addLabel={false}
                        pagination={<ShowPagination />}
                    >
                        <Datagrid
                            rowClick={null}
                        >
                            <TextField
                                source='ClassName'
                            />
                            <ColoredStatusCodeField
                                source='StatusCode'
                                headerClassName={classes.statusCode}
                                cellClassName={classes.statusCode}
                            />
                            <DateField
                                source='RequestDate'
                                headerClassName={classes.requestDate}
                                cellClassName={classes.requestDate}
                                showTime
                            />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                {Users.roles.includes(permissions) && <Tab
                    label='resources.members.tabs.security'
                    path='security'
                >
                    <AssignRoleButton />
                    <ReferenceField
                        source='UserID'
                        reference='users'
                        linkType={'show'}
                        allowEmpty={true}
                    >
                        <TextField source='RoleName' />
                    </ReferenceField>
                </Tab>}
            </TabbedShowLayout>
        </Show>
    );
});

export default MembersShow;