import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from '../../styles/dashboardStyles';

const CardIcon = withStyles(styles)(({ Icon, classes, color }) => (
    <Icon
        className={classes.icon}
        style={{ color: color }}
    />
));

export default CardIcon;