import React from 'react';
import {
    Show, TabbedShowLayout, Tab,  Datagrid, DeleteButton,
    TextField, DateField, ReferenceManyField, Pagination,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import styles from '../../styles/showStyles';

import { ColoredBooleanField, GroupField, LinkField, } from '../../components/fields';
import Title from './Title';

import { AddCourseButton } from '../../components/buttons';

import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';
import Zones from '../Zones';

const ShowPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const DistrictsShow = withStyles(styles)(({ permissions, classes,...props }) => (
    <Show title={<Title />} {...props}>
        <TabbedShowLayout>
            <Tab
                label='resources.districts.tabs.details'
            >
                <GroupField>
                    {MultiDistricts.roles.includes(permissions) ? <LinkField
                        source='MultiDistrictID'
                        reference='multidistricts'
                        addLabel={true}
                        className={classes.inlineBlock}
                    >
                        <TextField source='MultiDistrictName' />
                    </LinkField> : <TextField source='MultiDistrictName' className={classes.inlineBlock} />}

                    {Partners.roles.includes(permissions) ? <LinkField
                        source='PartnerID'
                        reference='partners'
                        addLabel={true}
                        className={classes.inlineBlock}
                    >
                        <TextField source='PartnerName' />
                    </LinkField> : <TextField source='PartnerName' className={classes.inlineBlock} />}
                </GroupField>

                {Zones.roles.includes(permissions) ? <LinkField
                    source='ZoneID'
                    reference='zones'
                    addLabel={true}
                >
                    <TextField source='ZoneName' />
                </LinkField> : <TextField source='ZoneName' />}

                <DateField
                    source='Expires'
                />
                <ColoredBooleanField
                    source='Active'
                />
                <DateField
                    source='CreateDate'
                    showTime
                    className={classes.inlineBlock}
                />
                <DateField
                    source='ModifiedDate'
                    showTime
                    className={classes.inlineBlock}
                />
            </Tab>
            <Tab
                label='resources.districts.tabs.clubs'
                path="clubs"
            >
                <ReferenceManyField
                    reference='clubs'
                    target='DistrictID'
                    sort={{ field: 'ClubName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid
                        rowClick='show'
                    >
                        <TextField
                            source='ClubName'
                        />
                        <TextField
                            source='StateCode'
                            headerClassName={classes.stateCode}
                            cellClassName={classes.stateCode}
                        />
                        <TextField
                            source='CountryCode'
                            headerClassName={classes.countryCode}
                            cellClassName={classes.countryCode}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab
                label='resources.districts.tabs.members'
                path='members'
            >
                <ReferenceManyField
                    reference='members'
                    target='DistrictID'
                    sort={{ field: 'LastName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid
                        rowClick='show'
                    >
                        <TextField
                            source='LastName'
                            headerClassName={classes.lastName}
                            cellClassName={classes.lastName}
                        />
                        <TextField
                            source='FirstName'
                            headerClassName={classes.firstName}
                            cellClassName={classes.firstName}
                        />
                        <TextField
                            source='Email'
                            headerClassName={classes.email}
                            cellClassName={classes.email}
                        />
                        <TextField
                            source='ClubName'
                            headerClassName={classes.clubName}
                            cellClassName={classes.clubName}
                        />
                        <TextField
                            source='StateCode'
                            headerClassName={classes.stateCode}
                            cellClassName={classes.stateCode}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab
                label='resources.districts.tabs.courses'
                path='courses'
            >
                <ReferenceManyField
                    reference='districtcourses'
                    target='DistrictID'
                    sort={{ field: 'ClassName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid >
                        <TextField
                            source='ClassTypeID'
                            label="ID"
                        />
                        <TextField
                            source='ClassName'
                        />
                        <DeleteButton
                            redirect={false}
                            className={classes.listButton}
                        />
                    </Datagrid>
                </ReferenceManyField>
                <AddCourseButton
                    to='/districtcourses/create?districtid='
                    label='resources.districtcourses.buttons.addCourse'
                />
            </Tab>
        </TabbedShowLayout>
    </Show>
));
export default DistrictsShow;