import React from 'react';
import {
    Show, SimpleShowLayout,
    DateField,
} from 'react-admin';
import { ColoredBooleanField } from '../../components/fields';
import Title from './Title';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const RolesShow = withStyles(styles)(({ classes,...props }) => (
    <Show title={<Title />} actions={null} {...props}>
        <SimpleShowLayout>
            <ColoredBooleanField
                source='Active'
            />
            <DateField
                source='CreateDate'
                showTime
                className={classes.inlineBlock}
            />
            <DateField
                source='ModifiedDate'
                showTime
                className={classes.inlineBlock}
            />
        </SimpleShowLayout>
    </Show>
));
export default RolesShow;