import Icon from './Icon';
import List from './List';
import Show from './Show';
import Edit from './Edit';
import Create from './Create';
import appRoles from '../../util/roles';

const roles = [
    appRoles.SysAdmin,
];

const name = 'countries';

export default { name, roles, Icon, List, Show, Edit, Create };