import React from 'react';
import {
    Create, SimpleForm,
    TextInput, ReferenceInput, AutocompleteInput,
} from 'react-admin';

import { required } from '../../util/fieldValidation';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const MembersCreate = withStyles(styles)(({ classes,...props }) => (
    <Create {...props}>
        <SimpleForm submitOnEnter={false} >
            <TextInput
                source='FirstName'
                validate={[required()]}
                formClassName={classes.inlineBlock}
            />
            <TextInput
                source='LastName'
                validate={[required()]}
                formClassName={classes.inlineBlock}
            />
            <TextInput
                source='Email'
                type="email"
                validate={[required()]}
                validation={{ email: true }}
                style={{ maxWidth: 500 }}
            />
            <ReferenceInput
                source='ClubID'
                reference='clubs'
                validate={[required()]}
                perPage={1000}
                sort={{ field: 'ClubName', order: 'ASC' }}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ ClubName_like: searchText })}
            >
                <AutocompleteInput
                    optionText='ClubName'
                />
            </ReferenceInput>
            <TextInput
                source='ExternalID'
            />
        </SimpleForm>
    </Create>
));
export default MembersCreate;