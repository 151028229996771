import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import { translate } from 'react-admin';

const styles = {
    'card': {
        'marginBottom': '2em',
    },
    'headline': {
        'marginBottom': '0.75em',
    },
    'logo': {
        'float': 'left',
        'height': '25%',
        'marginBottom': '1em',
        'marginRight': '1.5em',
        'maxHeight': '8em',
        'maxWidth': '8em',
        'minHeight': '6em',
        'minWidth': '6em',
        'width': '25%',
    },
    'button': {
        'float': 'right',
        'marginBottom': '2em',
        'marginRight': '1em',
        'marginTop': '1em',
    },
    'icon': {
        paddingRight: '0.25em',
    },
};

const Welcome = translate(({ translate }) => (
    <Card style={styles.card}>
        <CardContent>
            <Typography variant='title' style={styles.headline}>
                { translate('nayen.dashboard.welcome.title') }
            </Typography>
            <Typography
                style={styles.logo}
                component='img'
                src={ translate('nayen.dashboard.welcome.logoUrl')}
            />
            <Typography component='p'>
                { translate('nayen.dashboard.welcome.subTitle') }
            </Typography>
            <Button
                href={ translate('nayen.dashboard.welcome.homeUrl') }
                style={styles.button}
                color="primary"
                variant="contained"
            >
                <HomeIcon style={styles.icon} />
                { translate('nayen.dashboard.welcome.homeButton') }
            </Button>
        </CardContent>
    </Card>
));

export default Welcome;