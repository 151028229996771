import React from 'react';
import {
    Show, TabbedShowLayout, Tab, Datagrid,
    TextField, DateField, ReferenceManyField, Pagination,
} from 'react-admin';
import { ColoredBooleanField, LinkField, } from '../../components/fields';
import Title from './Title';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const ShowPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const StatesShow = withStyles(styles)(({ classes,...props }) => (
    <Show title={<Title />} {...props}>
        <TabbedShowLayout>
            <Tab
                label='resources.states.tabs.details'
            >
                <TextField
                    source='StateCode'
                    className={classes.inlineBlock}
                />
                <LinkField
                    source='CountryID'
                    reference='countries'
                    addLabel={true}
                    className={classes.inlineBlock}
                >
                    <TextField source='CountryName' />
                </LinkField>
                <ColoredBooleanField
                    source='Active'
                />
                <DateField
                    source='CreateDate'
                    showTime
                    className={classes.inlineBlock}
                />
                <DateField
                    source='ModifiedDate'
                    showTime
                    className={classes.inlineBlock}
                />
            </Tab>
            <Tab
                label='resources.states.tabs.clubs'
                path="clubs"
            >
                <ReferenceManyField
                    reference='clubs'
                    target='StateID'
                    sort={{ field: 'ClubName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid
                        rowClick='show'
                    >
                        <TextField
                            source='ClubName'
                        />
                        <TextField
                            source='DistrictName'
                            headerClassName={classes.districtName}
                            cellClassName={classes.districtName}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab
                label='resources.states.tabs.members'
                path='members'
            >
                <ReferenceManyField
                    reference='members'
                    target='StateID'
                    sort={{ field: 'LastName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid
                        rowClick='show'
                    >
                        <TextField
                            source='LastName'
                            headerClassName={classes.lastName}
                            cellClassName={classes.lastName}
                        />
                        <TextField
                            source='FirstName'
                            headerClassName={classes.firstName}
                            cellClassName={classes.firstName}
                        />
                        <TextField
                            source='Email'
                            headerClassName={classes.email}
                            cellClassName={classes.email}
                        />
                        <TextField
                            source='ClubName'
                            headerClassName={classes.clubName}
                            cellClassName={classes.clubName}
                        />
                        <TextField
                            source='DistrictName'
                            headerClassName={classes.districtName}
                            cellClassName={classes.districtName}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
));
export default StatesShow;