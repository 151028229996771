import React from 'react';
import {
    List, Datagrid,
    TextField, DateField,
    Pagination,
} from 'react-admin';
import ListActions from '../../components/actions/ListActions';
import { ColoredScoreField } from '../../components/fields';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/listStyles';

import Clubs from '../Clubs';
import Districts from '../Districts';
import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';

import exporter from './exporter';
import ListFilters from  './ListFilters';

const TrainingsPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100, 250, 500, 1000]} {...props} />;

const TrainingsList = withStyles(styles)(({permissions, classes, ...props}) => (
    <List
        actions={<ListActions permissions={permissions} hideCreate={true} />}
        pagination={<TrainingsPagination />}
        perPage={ 50 }
        bulkActionButtons={ false }
        filters={<ListFilters permissions={permissions} />}
        sort={{ field: 'TrainedDt', order: 'DESC' }}
        exporter={exporter}
        { ...props }
    >
        <Datagrid
            rowClick='show'
        >
            <TextField
                source='LastName'
                headerClassName={classes.lastName}
                cellClassName={classes.lastName}
            />
            <TextField
                source='FirstName'
                headerClassName={classes.firstName}
                cellClassName={classes.firstName}
            />
            {Clubs.roles.includes(permissions) && <TextField
                source='ClubName'
                headerClassName={classes.clubName}
                cellClassName={classes.clubName}
            />}

            {Districts.roles.includes(permissions) && <TextField
                source='DistrictName'
                headerClassName={classes.districtName}
                cellClassName={classes.districtName}
            />}

            {MultiDistricts.roles.includes(permissions) && <TextField
                source='MultiDistrictName'
                headerClassName={classes.multiDistrictName}
                cellClassName={classes.multiDistrictName}
            />}

            {Partners.roles.includes(permissions) && <TextField
                source='PartnerName'
                headerClassName={classes.partnerName}
                cellClassName={classes.partnerName}
            />}

            <TextField
                source='ClassName'
                headerClassName={classes.className}
                cellClassName={classes.className}
            />
            <ColoredScoreField
                source='Score'
                options={{ maximumFractionDigits: 0 }}
                headerClassName={classes.score}
                cellClassName={classes.score}
            />
            <DateField
                source='TrainedDt'
                headerClassName={classes.trainedDate}
                cellClassName={classes.trainedDate}
            />
            <DateField
                source='RequestDate'
                headerClassName={classes.requestDate}
                cellClassName={classes.requestDate}
            />
        </Datagrid>
    </List>
));
export default TrainingsList;