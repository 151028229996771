import { downloadCSV } from 'react-admin';
import { unparse } from 'papaparse/papaparse.min';

const name = 'volunteers';
const fields = ['id', 'ExternalID', 'LastName', 'FirstName', 'Email', 'ClubName', 'DistrictName', 'MultiDistrictName', 'PartnerName'];

export default records => {
    const csv = unparse({
        data: records,
        fields: fields, // order fields in the export
    });
    downloadCSV(csv, name); // download as csv file
};