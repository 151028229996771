import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Labeled }from 'react-admin';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        padding: '0 !important',
    },
};


const sanitizeRestProps = ({
    children,
    className,
    record,
    resource,
    basePath,
    version,
    initialValues,
    translate,
    ...rest
}) => rest;

const GroupField = ({
    basePath,
    classes,
    className,
    children,
    record,
    resource,
    version,
    ...rest
}) => (
    <CardContent
        className={classnames(classes.root, className)}
        key={version}
        {...sanitizeRestProps(rest)}
    >
        {Children.map(
            children,
            field =>
                field ? (
                    <div
                        key={field.props.source}
                        className={classnames(
                            `ra-field ra-field-${field.props.source}`,
                            field.props.className
                        )}
                    >
                        {field.props.addLabel ? (
                            <Labeled
                                record={record}
                                resource={resource}
                                basePath={basePath}
                                label={field.props.label}
                                source={field.props.source}
                                disabled={false}
                            >
                                {field}
                            </Labeled>
                        ) : typeof field.type === 'string' ? (
                            field
                        ) : (
                            React.cloneElement(field, {
                                record,
                                resource,
                                basePath,
                            })
                        )}
                    </div>
                ) : null
        )}
    </CardContent>
);

GroupField.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    record: PropTypes.object,
    resource: PropTypes.string,
    version: PropTypes.number,
};

export default withStyles(styles)(GroupField);