import React from 'react';
import {
    Show, SimpleShowLayout,
    TextField, DateField, EmailField, NumberField, FunctionField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import styles from '../../styles/showStyles';

import {
    GroupField, ColoredScoreField, LinkField,
} from '../../components/fields';
import Title from './Title';

import Clubs from '../Clubs';
import Districts from '../Districts';
import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';
import Courses from '../Courses';

const TrainingShow = withStyles(styles)(({ permissions, classes, ...props }) => (
    <Show title={<Title />} actions={null} {...props}>
        <SimpleShowLayout>
            <ColoredScoreField
                source='Score'
                className={classes.score}
            />
            <GroupField>
                <LinkField
                    source='MemberID'
                    reference='members'
                    addLabel={true}
                    className={classes.inlineBlock}
                >
                    <FunctionField
                        render={(record) => (
                            `${record.FirstName} ${record.LastName}`
                        )}
                    />
                </LinkField>
                <EmailField
                    source='Email'
                    className={classes.inlineBlock}
                />
            </GroupField>

            <GroupField>
                {Clubs.roles.includes(permissions) ? <LinkField
                    source='ClubID'
                    reference='clubs'
                    addLabel={true}
                    className={classes.inlineBlock}
                >
                    <TextField source='ClubName' />
                </LinkField> : <TextField source='ClubName' className={classes.inlineBlock} /> }

                {Districts.roles.includes(permissions) ? <LinkField
                    source='DistrictID'
                    reference='districts'
                    addLabel={true}
                    className={classes.inlineBlock}
                >
                    <TextField source='DistrictName' />
                </LinkField> : <TextField source='DistrictName' className={classes.inlineBlock} /> }
            </GroupField>

            <GroupField>
                {MultiDistricts.roles.includes(permissions) ? <LinkField
                    source='MultiDistrictID'
                    reference='multidistricts'
                    addLabel={true}
                    className={classes.inlineBlock}
                >
                    <TextField source='MultiDistrictName' />
                </LinkField> : <TextField source='MultiDistrictName' className={classes.inlineBlock} />}

                {Partners.roles.includes(permissions) ? <LinkField
                    source='PartnerID'
                    reference='partners'
                    addLabel={true}
                    className={classes.inlineBlock}
                >
                    <TextField source='PartnerName' />
                </LinkField> : <TextField source='PartnerName' className={classes.inlineBlock} />}
            </GroupField>

            <GroupField>
                <TextField
                    source='id'
                    className={classes.inlineBlock}
                />
                <TextField
                    source='ExternalID'
                    className={classes.inlineBlock}
                />
            </GroupField>

            <GroupField>
                {Courses.roles.includes(permissions) ? <LinkField
                    source='ClassTypeID'
                    reference='courses'
                    addLabel={true}
                    className={classes.inlineBlock}
                >
                    <TextField source='ClassName' />
                </LinkField> : <TextField source='ClassName' className={classes.inlineBlock} />}

                <NumberField
                    source='PassingScore'
                    className={classes.inlineBlock}
                />
            </GroupField>

            <GroupField>
                <DateField
                    source='TrainedDt'
                    className={classes.inlineBlock}
                    showTime
                />
                <NumberField
                    source='Score'
                    className={classes.inlineBlock}
                />
            </GroupField>

            <DateField
                source='RequestDate'
                showTime
            />

        </SimpleShowLayout>
    </Show>
));
export default TrainingShow;