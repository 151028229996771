import React from 'react';
import {
    Show, TabbedShowLayout, Tab, Datagrid,
    TextField, NumberField, DateField, ReferenceManyField, Pagination,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import styles from '../../styles/showStyles';

import { ColoredBooleanField } from '../../components/fields';
import Title from './Title';

const ClassUrlField = ({ record = {} }) => (
    <span>
        <a href={record.URL + '?memberid='} rel="noopener noreferrer" target="_blank">
            {record.URL}?memberid=
        </a>
    </span>
);
ClassUrlField.defaultProps = { addLabel: true, label: 'URL' };

const ShowPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const CoursesShow = withStyles(styles)(({ classes,...props }) => (
    <Show title={<Title />} {...props}>
        <TabbedShowLayout>
            <Tab
                label='resources.courses.tabs.details'
            >
                <NumberField
                    source='id'
                />
                <NumberField
                    source='PassingScore'
                />
                <ClassUrlField
                    source='URL'
                />
                <ColoredBooleanField
                    source='Active'
                />
                <DateField
                    source='CreateDate'
                    showTime
                    className={classes.inlineBlock}
                />
                <DateField
                    source='ModifiedDate'
                    showTime
                    className={classes.inlineBlock}
                />
            </Tab>
            <Tab
                label='resources.courses.tabs.partners'
                path='partners'
            >
                <ReferenceManyField
                    reference='partnercourses'
                    target='ClassTypeID'
                    sort={{ field: 'PartnerName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid >
                        <TextField
                            source='PartnerName'
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab
                label='resources.courses.tabs.districts'
                path='districts'
            >
                <ReferenceManyField
                    reference='districtcourses'
                    target='ClassTypeID'
                    sort={{ field: 'DistrictName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid {...props}>
                        <TextField
                            source='DistrictName'
                        />
                        <TextField
                            source='MultiDistrictName'
                            headerClassName={classes.multiDistrictName}
                            cellClassName={classes.multiDistrictName}
                        />
                        <TextField
                            source='PartnerName'
                            headerClassName={classes.partnerName}
                            cellClassName={classes.partnerName}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
));

export default CoursesShow;