import React from 'react';
import { Layout, Sidebar } from 'react-admin';
import CustomAppBar from './AppBar';
import CustomMenu from './Menu';
import CustomNotification from './Notification';

const CustomSidebar = (props) => (
    <Sidebar
        {...props}
        size={200}
    />
);

const CustomLayout = (props) => (
    <Layout
        {...props}
        appBar={CustomAppBar}
        menu={CustomMenu}
        notification={CustomNotification}
        sidebar={CustomSidebar}
    />
);

export default CustomLayout;