import React from 'react';
import { Responsive, Title, translate } from 'react-admin';

import WelcomeCard from './Welcome';
import AreasCard from './Areas';
import TrainingsCard from './Trainings';
import SysAdminCard from './SysAdmin';

import Clubs from '../../pages/Clubs';
import Trainings from '../../pages/Trainings';
import Users from '../../pages/Users';

const styles = {
    flex: {
        display: 'flex',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        margin: '1em',
        maxWidth: 'calc(100vw - 2em)',
    },
    leftCol: {
        flex: 1,
        marginLeft: '0.5em',
        marginRight: '1em',
    },
    rightCol: {
        flex: 1,
        marginLeft: '1em',
    },
    singleCol: {
        marginTop: '0',
        marginBottom: '2em',
    },
};

const Dashboard = translate(({ translate, permissions, }) => (
    <React.Fragment>
        <Title title={translate('nayen.title')} />
        <Responsive
            small={
                <div style={styles.flexColumn}>
                    <div style={styles.singleCol}>
                        <WelcomeCard />
                        {Trainings.roles.includes(permissions) && <TrainingsCard permissions={permissions} />}
                        {Clubs.roles.includes(permissions) && <AreasCard permissions={permissions} />}
                        {Users.roles.includes(permissions) && <SysAdminCard permissions={permissions} />}
                    </div>
                </div>
            }
            medium={
                <div>
                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            <div style={styles.singleCol}>
                                <WelcomeCard />
                                {Trainings.roles.includes(permissions) && <TrainingsCard permissions={permissions} />}
                            </div>
                        </div>

                        <div style={styles.rightCol}>
                            <div style={styles.singleCol}>
                                {Clubs.roles.includes(permissions) && <AreasCard permissions={permissions} />}
                                {Users.roles.includes(permissions) && <SysAdminCard permissions={permissions} />}
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
    </React.Fragment>
));

export default Dashboard;