import React from 'react';
import {
    Show, TabbedShowLayout, Tab,  Datagrid, DeleteButton,
    TextField, EmailField, DateField, ReferenceManyField, Pagination,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

import { ColoredBooleanField } from '../../components/fields';
import Title from './Title';

import { AddCourseButton } from '../../components/buttons';

const ShowPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const PartnersShow = withStyles(styles)(({ classes,...props }) => (
    <Show title={<Title />} {...props}>
        <TabbedShowLayout>
            <Tab
                label='resources.partners.tabs.details'
            >
                <TextField
                    source='ContactName'
                />
                <EmailField
                    source='ContactEMail'
                />
                <DateField
                    source="Expires"
                />
                <ColoredBooleanField
                    source='Active'
                />
                <DateField
                    source='CreateDate'
                    showTime
                    className={classes.inlineBlock}
                />
                <DateField
                    source='ModifiedDate'
                    showTime
                    className={classes.inlineBlock}
                />
            </Tab>
            <Tab
                label='resources.partners.tabs.districts'
                path='districts'
            >
                <ReferenceManyField
                    reference='districts'
                    target='PartnerID'
                    sort={{ field: 'DistrictName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid
                        rowClick='show'
                    >
                        <TextField
                            source='DistrictName'
                        />
                        <TextField
                            source='MultiDistrictName'
                            headerClassName={classes.multiDistrictName}
                            cellClassName={classes.multiDistrictName}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab
                label='resources.partners.tabs.clubs'
                path='clubs'
            >
                <ReferenceManyField
                    reference='clubs'
                    target='PartnerID'
                    sort={{ field: 'ClubName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid
                        rowClick='show'
                    >
                        <TextField
                            source='ClubName'
                        />
                        <TextField
                            source='DistrictName'
                            headerClassName={classes.districtName}
                            cellClassName={classes.districtName}
                        />
                        <TextField
                            source='StateCode'
                            headerClassName={classes.stateCode}
                            cellClassName={classes.stateCode}
                        />
                        <TextField
                            source='CountryCode'
                            headerClassName={classes.countryCode}
                            cellClassName={classes.countryCode}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab
                label='resources.partners.tabs.members'
                path='members'
            >
                <ReferenceManyField
                    reference='members'
                    target='PartnerID'
                    sort={{ field: 'LastName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid
                        rowClick='show'
                    >
                        <TextField
                            source='LastName'
                            headerClassName={classes.lastName}
                            cellClassName={classes.lastName}
                        />
                        <TextField
                            source='FirstName'
                            headerClassName={classes.firstName}
                            cellClassName={classes.firstName}
                        />
                        <TextField
                            source='Email'
                            headerClassName={classes.email}
                            cellClassName={classes.email}
                        />
                        <TextField
                            source='ClubName'
                            headerClassName={classes.clubName}
                            cellClassName={classes.clubName}
                        />
                        <TextField
                            source='DistrictName'
                            headerClassName={classes.districtName}
                            cellClassName={classes.districtName}
                        />
                        <TextField
                            source='StateCode'
                            headerClassName={classes.stateCode}
                            cellClassName={classes.stateCode}
                        />
                        <TextField
                            source='CountryCode'
                            headerClassName={classes.countryCode}
                            cellClassName={classes.countryCode}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab
                label='resources.partners.tabs.courses'
                path='courses'
            >
                <ReferenceManyField
                    reference='partnercourses'
                    target='PartnerID'
                    sort={{ field: 'ClassName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid >
                        <TextField
                            source='ClassTypeID'
                            label="ID"
                        />
                        <TextField
                            source='ClassName'
                        />
                        <DeleteButton
                            redirect={false}
                            className={classes.listButton}
                        />
                    </Datagrid>
                </ReferenceManyField>
                <AddCourseButton
                    to='/partnercourses/create?partnerid='
                    label='resources.partnercourses.buttons.addCourse'
                />
            </Tab>
        </TabbedShowLayout>
    </Show>
));
export default PartnersShow;