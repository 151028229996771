const styles = (theme) => ({
    active: {
        width: '80px',
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },

    className: {
        '& span': {
            minWidth: '120px',
            maxWidth: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },

    clubName: {
        '& span': {
            minWidth: '80px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },

    contactName: {
        '& span': {
            minWidth: '100px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },

    countryCode: {
        '& span': {
            maxWidth: '100px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },

    countryName: {
        '& span': {
            maxWidth: '100px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },

    createDate: {
        minWidth: '100px',
        [theme.breakpoints.down('md')]: {
            display: 'none !important',
        },
    },

    districtName: {
        '& span': {
            minWidth: '100px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },

    firstName: {
        '& span': {
            minWidth: '100px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },

    email: {
        minWidth: '120px',
        maxWidth: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },

    lastLogin: {
        minWidth: '80px',
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },

    lastName: {
        '& span': {
            minWidth: '100px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },

    lastPwdChange: {
        minWidth: '120px',
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },

    listButton: {
        minWidth: 0,
        padding: 0,
        '& span': {
            '& span': {
                display: 'none',
            },
        },
    },

    modifiedDate: {
        minWidth: '100px',
        [theme.breakpoints.down('md')]: {
            display: 'none !important',
        },
    },

    multiDistrictName: {
        '& span': {
            minWidth: '90px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },

    partnerName: {
        '& span': {
            minWidth: '90px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },

    passingScore: {
        minWidth: '110px',
    },

    requestDate: {
        minWidth: '100px',
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },

    roleName: {
        '& span': {
            minWidth: '90px',
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },

    score: {
        minWidth: '60px',
        textAlign: 'center !important',
        '& div': {
            fontSize: '0.95em',
            height: '28px',
            width: '28px',
        },
    },

    stateCode: {
        '& span': {
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },

    stateName: {
        '& span': {
            maxWidth: '125px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('md')]: {
            display: 'none !important',
        },
    },

    trainedDate: {
        minWidth: '120px',
        [theme.breakpoints.down('xs')]: {
            display: 'none !important',
        },
    },

    zoneName: {
        '& span': {
            maxWidth: '300px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
});
export default styles;