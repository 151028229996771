import React from 'react';
import { BooleanField } from 'react-admin';

import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

const styles = {
    true: { color: green[800], 'fontWeight': 800 },
    false: { color: grey[400] }
};

const colored = WrappedComponent => {
    const component = props => {
        props.record[props.source] = (props.record[props.source] > 0);
        return (
            (props.record[props.source]) ? (
                <WrappedComponent {...props} style={styles.true} />
            ) : null
        );
    };

    component.displayName = `Colored(${WrappedComponent.displayName})`;

    return component;
};

const ColoredStatusCodeField = colored(BooleanField);

// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredStatusCodeField.defaultProps = BooleanField.defaultProps;

export default ColoredStatusCodeField;