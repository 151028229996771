import React from 'react';
import { translate } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import DashboardCard from './DashboardCard';

import Trainings from '../../pages/Trainings';
import Members from '../../pages/Members';

import styles from '../../styles/dashboardStyles';

import red from '@material-ui/core/colors/red';
import deepPurple from '@material-ui/core/colors/deepPurple';

const colorIndex = 'A700';

const items = [
    {name: Members.name, icon: Members.Icon, color: red[colorIndex], roles: Members.roles},
    {name: Trainings.name, icon: Trainings.Icon, color: deepPurple[colorIndex], roles: Trainings.roles},
];

const GroupCard = translate(({ translate, permissions }) => (
    <Paper style={ styles.paper }>
        <Typography variant='title'>
            { translate('nayen.dashboard.groups.trainings') }
        </Typography>
        {items.map(item => {
            return item.roles.includes(permissions) && <DashboardCard key={item.name} item={item} />;
        })}
    </Paper>
));

export default GroupCard;