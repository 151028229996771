import Create from './Create';
import appRoles from '../../util/roles';

const roles = [
    appRoles.SysAdmin,
];


const name = 'partnercourses';

export default { name, roles, Create };