import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import decodeJwt from 'jwt-decode';

import dataProvider from '../../dataProvider';
import gqlFetch from '../../util/gqlFetch';

import { locales } from '../../locales';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { PasswordStrengthField, GroupField } from '../fields';

import {
    translate, changeLocale,
    showNotification as showNotificationAction,
    Title, SimpleShowLayout, TextField as RATextField, DateField, Labeled,
    GET_LIST,
} from 'react-admin';

import { withStyles } from '@material-ui/core/styles';

const tokenKey = 'auth_token';

const styles = {
    inlineBlock: {
        display: 'inline-block',
        marginRight: '1em',
    },
    hr: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        border: 'none',
        height: '1px',
        margin: '0 1.5em'
    },
    label: {
        fontWeight: 'bold',
    },
    passwordForm: {
        width: '300px',
        maxWidth: '95%',
    },
    passwordField: {
        marginBottom: '1.5em',
    },
    button: {
        margin: '0.5em 1.5em 1em 0',
    },
};

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

class Profile extends Component {
    state = {
        error: false,
        showDialog: false,
        user: {},
    };

    componentDidMount() {
        const token = localStorage.getItem(tokenKey);
        const decodedToken = decodeJwt(token);
        let UserID = 0;
        if (decodedToken && decodedToken.data) {
            UserID = decodedToken.data.user;
        }
        dataProvider().then(provider => {
            provider(GET_LIST, 'users', {
                filter: { ids: [UserID] },
                sort: { field: 'id', order: 'ASC' },
                pagination: { page: 1, perPage: 1 },
            }).then(response => {
                response.data && response.data.length && this.setState({
                    user: response.data[0]
                })
            });
        });
    }

    changePassword = (values) => {
        const { showNotification } = this.props;
        const mutation = 'updatePassword';
        const json = {
            query: 'mutation {' + mutation + '(currentPassword: "' + values.currentpassword + '", newPassword: "' + values.newpassword + '")}'
        };

        return gqlFetch(json)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                if (response && response.data && (response.data[mutation] === true)) {
                    showNotification('nayen.profile.passwordUpdated');
                } else {
                    showNotification('nayen.profile.passwordAuthenticationFailed', 'warning')
                }
            })
            .catch((e) => {
                console.error(e);
                showNotification('nayen.profile.passwordUpdateError', 'warning')
            });
    }

    render() {
        const { user, } = this.state;

        const { classes, handleSubmit, locale, changeLocale, translate, invalid, pristine, submitting } = this.props;
        return (
            <React.Fragment>
                <Title title={translate('resources.profile.name') + ': ' + user.FirstName + ' ' + user.LastName} />
                <Card>
                    <SimpleShowLayout record={user} resource='users'>
                        <GroupField>
                            <RATextField
                                className={classes.inlineBlock}
                                source='ClubName'
                            />
                            <RATextField
                                className={classes.inlineBlock}
                                source='DistrictName'
                            />
                            <RATextField
                                className={classes.inlineBlock}
                                source='MultiDistrictName'
                            />
                            <RATextField
                                className={classes.inlineBlock}
                                source='PartnerName'
                            />
                        </GroupField>
                        <GroupField>
                            <DateField
                                source='FirstLogin'
                                showTime
                                className={classes.inlineBlock}
                            />
                            <DateField
                                source='LastLogin'
                                showTime
                                className={classes.inlineBlock}
                            />
                        </GroupField>
                        <GroupField>
                            <DateField
                                source='LastPwdChange'
                                showTime
                                className={classes.inlineBlock}
                            />
                            <DateField
                                source='NextPwdChange'
                                className={classes.inlineBlock}
                            />
                        </GroupField>
                    </SimpleShowLayout>
                    <CardContent>
                        <Labeled
                            className={classes.label}label='nayen.language'
                        />
                        {
                            Object.keys(locales).map(key => (
                                <Button
                                    key={key}
                                    variant='contained'
                                    className={classes.button}
                                    color={(locale === key) ? 'primary' : 'default'}
                                    onClick={() => changeLocale(key)}
                                >
                                    {locales[key].name}
                                </Button>
                            ))
                        }
                    </CardContent>
                    <hr className={classes.hr}/>
                    <CardContent>
                        <Labeled
                            className={classes.label}label='nayen.profile.changePassword'
                        />
                        <form onSubmit={handleSubmit(this.changePassword)} className={classes.passwordForm} >
                            <Field
                                name="currentpassword"
                                label={translate('nayen.profile.currentPassword')}
                                type="password"
                                component={renderInput}
                                className={classes.passwordField}
                            />
                            <PasswordStrengthField
                                minLength={8}
                                minScore={3}
                                scoreWords={[
                                    translate('nayen.profile.passwordWeak'),
                                    translate('nayen.profile.passwordWeak'),
                                    translate('nayen.profile.passwordOk'),
                                    translate('nayen.profile.passwordGood'),
                                    translate('nayen.profile.passwordStrong'),
                                ]}
                                tooShortWord={translate('nayen.profile.passwordTooShort')}
                                className={classes.passwordField}
                                inputProps={{
                                    name: "newpassword",
                                    label: translate('nayen.profile.newPassword'),
                                    autoComplete: "off",
                                }}
                            />
                            <Field
                                name="newpassword2"
                                label={translate('nayen.profile.verifyNewPassword')}
                                type="password"
                                component={renderInput}
                                className={classes.passwordField}
                            />
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                className={classes.button}
                                disabled={invalid || pristine || submitting}
                            >
                                {translate('nayen.profile.changePassword')}
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

Profile.propTypes = {
    ...propTypes,
    classes: PropTypes.object,
    locale: PropTypes.string,
    changeLocale: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    showNotification: PropTypes.func,
};

const mapStateToProps = state => ({
    locale: state.i18n.locale,
});

const enhance = compose(
    translate,
    reduxForm({
        form: 'changePassword',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.currentpassword) {
                errors.currentpassword = translate('ra.validation.required');
            }
            if (!values.newpassword) {
                errors.newpassword = translate('ra.validation.required');
            }
            if (values.newpassword && values.newpassword.length < 12) {
                errors.newpassword = translate('nayen.profile.passwordIsTooShort');
            }
            if (!values.newpassword2) {
                errors.newpassword2 = translate('ra.validation.required');
            }
            if (values.currentpassword && values.newpassword && values.currentpassword === values.newpassword) {
                errors.newpassword = translate('nayen.profile.passwordMustBeUnique');
            }
            if (values.newpassword && values.newpassword2 && values.newpassword !== values.newpassword2) {
                errors.newpassword2 = translate('nayen.profile.passwordsMustMatch');
            }
            return errors;
        },
    }),
    connect(mapStateToProps, {
        changeLocale,
        showNotification: showNotificationAction,
    }),
    translate,
    withStyles(styles)
);

export default enhance(Profile);