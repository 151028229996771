import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { change, submit, isSubmitting } from 'redux-form';
import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    refreshView,
    Button,
    SaveButton,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    FunctionField,
    translate,
    CREATE,
} from 'react-admin';

import EmailIcon from '@material-ui/icons/Email';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import dataProvider from '../../dataProvider';

class SendTrainingRequestButton extends Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit('member-training-request-create');
    };

    handleSubmit = values => {
        const {
            record,
            fetchStart,
            fetchEnd,
            showNotification
        } = this.props;

        const createRecord = {
            MemberID: record.id,
            ClassTypeID: values.ClassTypeID,
            RequestDate: new Date().toString(),
            StatusCode: 0
        };

        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();

        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        dataProvider().then(provider => {
            provider(CREATE, 'membertrainingrequests', { data: createRecord })
                .then(({ data }) => {
                    if (data) {
                        showNotification('resources.members.trainingRequests.successMessage');
                        refreshView();
                    } else {
                        showNotification('resources.members.trainingRequests.errorMessage', 'warning')
                    }
                    this.setState({ showDialog: false });
                })
                .catch((error) => {
                    console.error(error);
                    showNotification('resources.members.trainingRequests.errorMessage', 'warning')
                })
                .finally(() => {
                    // Dispatch an action letting react-admin know a API call has ended
                    fetchEnd();
                });
        });
    };

    render() {
        const { showDialog } = this.state;
        const { record, isSubmitting, translate } = this.props;

        return (
            <Fragment>
                <Button
                    onClick={this.handleClick}
                    label='resources.members.trainingRequests.buttonLabel'
                    variant='contained'
                    style={{float:'right', margin:'20px'}}
                >
                    <EmailIcon />
                </Button>
                <Dialog
                    fullWidth
                    disableEnforceFocus
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label={ translate('resources.members.trainingRequests.dialogTitle') }
                >
                    <DialogTitle>{ translate('resources.members.trainingRequests.dialogTitle') }</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            // We override the redux-form name to avoid collision with the react-admin main form
                            form='member-training-request-create'
                            resource='trainings'
                            // We override the redux-form onSubmit prop to handle the submission ourselves
                            onSubmit={this.handleSubmit}
                            // We want no toolbar at all as we have our modal actions
                            toolbar={null}
                        >
                            <FunctionField
                                label='resources.members.fields.FullName'
                                render={() => (
                                    `${record.FirstName} ${record.LastName}`
                                )}
                            />
                            <ReferenceInput
                                source='ClassTypeID'
                                reference='viewmembercourses'
                                validate={[required()]}
                                sort={{ field: 'ClassName', order: 'ASC' }}
                                perPage={100}
                                filter={{MemberID: record.id}}
                                filterToQuery={searchText => ({ ClubName_like: searchText })}
                            >
                                <SelectInput
                                    optionText='ClassName'
                                />
                            </ReferenceInput>
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            label='resources.members.trainingRequests.sendButtonLabel'
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button
                            label='ra.action.cancel'
                            onClick={this.handleCloseClick}
                        >
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('member-training-request-create')(state)
});

SendTrainingRequestButton.propTypes = {
    translate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    refreshView,
    showNotification,
    submit
};

const enhance = compose(
    translate,
)

export default enhance(connect(mapStateToProps, mapDispatchToProps)(
    SendTrainingRequestButton
));