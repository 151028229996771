import React from 'react';
import { Filter, TextInput, DateInput, NullableBooleanInput, } from 'react-admin';

import Clubs from '../Clubs';
import Districts from '../Districts';
import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';

const ListFilters = ({permissions, ...props}) => (
    <Filter {...props}>
        <TextInput
            source='LastName_like'
            label='resources.trainings.fields.LastName'
            alwaysOn
            resettable
        />
        <TextInput
            source='FirstName_like'
            label='resources.trainings.fields.FirstName'
            resettable
        />

        <TextInput
            source="Email_like"
            label="resources.trainings.fields.Email"
            resettable
        />

        {Clubs.roles.includes(permissions) && <TextInput
            source="ClubName_like"
            label="resources.trainings.fields.ClubName"
            resettable
        />}

        {Districts.roles.includes(permissions) && <TextInput
            source="DistrictName_like"
            label="resources.trainings.fields.DistrictName"
            resettable
        />}

        {MultiDistricts.roles.includes(permissions) && <TextInput
            source="MultiDistrictName_like"
            label="resources.trainings.fields.MultiDistrictName"
            resettable
        />}

        {Partners.roles.includes(permissions) && <TextInput
            source='PartnerName_like'
            label='resources.trainings.fields.PartnerName'
            resettable
        />}

        <TextInput
            source='ExternalID_like'
            label='resources.trainings.fields.ExternalID'
            resettable
        />

        <DateInput
            source='TrainedDt_gte'
            label='resources.trainings.fields.TrainedDtGTE'
            resettable
        />

        <DateInput
            source='TrainedDt_lte'
            label='resources.trainings.fields.TrainedDtLTE'
            resettable
        />

        <TextInput
            source='ClassName_like'
            label='resources.trainings.fields.ClassName'
            resettable
        />

        <NullableBooleanInput
            source="Passed"
            label="resources.trainings.fields.Passed"
            alwaysOn
        />

    </Filter>
);
export default ListFilters;