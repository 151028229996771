import React from 'react';
import {
    List, Datagrid,
    TextField, DateField
} from 'react-admin';

import ListActions from '../../components/actions/ListActions';
import { ColoredBooleanField } from '../../components/fields';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/listStyles';

import Districts from '../Districts';
import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';

import ListFilters from './ListFilters';

const ClubsList = withStyles(styles)(({permissions, classes, ...props}) => (
    <List
        actions={<ListActions permissions={permissions} />}
        perPage={ 25 }
        bulkActionButtons={ false }
        filters={<ListFilters permissions={permissions} />}
        filterDefaultValues={{ Active: true }}
        sort={{ field: 'ClubName', order: 'ASC' }}
        { ...props }
    >
        <Datagrid
            rowClick='show'
        >
            <TextField
                source='ClubName'
            />
            <TextField
                source='StateCode'
                headerClassName={classes.stateCode}
                cellClassName={classes.stateCode}
            />
            <TextField
                source='CountryCode'
                headerClassName={classes.countryCode}
                cellClassName={classes.countryCode}
            />
            {Districts.roles.includes(permissions) && <TextField
                source='DistrictName'
                headerClassName={classes.districtName}
                cellClassName={classes.districtName}
            />}
            {MultiDistricts.roles.includes(permissions) && <TextField
                source='MultiDistrictName'
                headerClassName={classes.multiDistrictName}
                cellClassName={classes.multiDistrictName}
            />}
            {Partners.roles.includes(permissions) && <TextField
                source='PartnerName'
                headerClassName={classes.partnerName}
                cellClassName={classes.partnerName}
            />}
            <ColoredBooleanField
                source='Active'
                headerClassName={classes.active}
                cellClassName={classes.active}
            />
            <DateField
                source='CreateDate'
                headerClassName={classes.createDate}
                cellClassName={classes.createDate}
            />
            <DateField
                source='ModifiedDate'
                headerClassName={classes.modifiedDate}
                cellClassName={classes.modifiedDate}
            />
        </Datagrid>
    </List>
));
export default ClubsList;