import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

const FullNameField = ({ record = {} }) => (
    <span>
        {record.FirstName} {record.LastName}
    </span>
);

FullNameField.propTypes = {
    record: PropTypes.object,
};

const PureFullNameField = pure(FullNameField);

PureFullNameField.defaultProps = {
    source: 'LastName',
    label: 'resources.members.fields.FullName',
};

export default PureFullNameField;