import React from 'react';
import {
    Edit, SimpleForm,
    TextInput, BooleanInput, ReferenceInput, AutocompleteInput, DateField, DateInput,
} from 'react-admin';
//import { DateInput } from 'react-admin-date-inputs';
import Toolbar from '../../components/toolbars/EditToolbar';
import Title from './Title';

import { required } from '../../util/fieldValidation';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const DistrictsEdit = withStyles(styles)(({ classes,...props }) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<Toolbar />} submitOnEnter={false} >
            <TextInput
                source='DistrictName'
                validate={[required()]}
            />

            <ReferenceInput
                source='MultiDistrictID'
                reference='multidistricts'
                sort={{ field: 'MultiDistrictName', order: 'ASC' }}
                perPage={1000}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ MultiDistrictName_like: searchText })}
                formClassName={classes.inlineBlock}
            >
                <AutocompleteInput optionText='MultiDistrictName' />
            </ReferenceInput>

            <ReferenceInput
                source='PartnerID'
                reference='partners'
                validate={[required()]}
                sort={{ field: 'PartnerName', order: 'ASC' }}
                perPage={1000}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ PartnerName_like: searchText })}
                formClassName={classes.inlineBlock}
            >
                <AutocompleteInput optionText='PartnerName' />
            </ReferenceInput>

            <ReferenceInput
                source='ZoneID'
                reference='zones'
                sort={{ field: 'ZoneName', order: 'ASC' }}
                perPage={1000}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ ZoneName_like: searchText })}
            >
                <AutocompleteInput optionText='ZoneName' />
            </ReferenceInput>

            <DateInput
                source='Expires'
                options={{
                    format: 'MM/DD/YYYY',
                    //clearable: true,
                    //keyboard: true
                }}
            />
            <BooleanInput
                source='Active'
            />
            <DateField
                source='CreateDate'
                showTime
                formClassName={classes.inlineBlock}
            />
            <DateField
                source='ModifiedDate'
                showTime
                formClassName={classes.inlineBlock}
            />
        </SimpleForm>
    </Edit>
));
export default DistrictsEdit;