import React from 'react';
import {
    Show, TabbedShowLayout, Tab, Datagrid,
    TextField, DateField, ReferenceManyField, Pagination,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import styles from '../../styles/showStyles';

import { ColoredBooleanField, GroupField, LinkField, }from '../../components/fields';
import Title from './Title';

import Districts from '../Districts';
import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';
import Zones from '../Zones';
import States from '../States';
import Countries from '../Countries';

const ShowPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const ClubsShow = withStyles(styles)(({ permissions, classes,...props }) => (
    <Show title={<Title/>} {...props}>
        <TabbedShowLayout>
            <Tab
                label='resources.clubs.tabs.details'
            >
                <GroupField>
                    {States.roles.includes(permissions) ? <LinkField
                        source='StateID'
                        reference='states'
                        addLabel={true}
                        className={classes.inlineBlock}
                    >
                        <TextField source='StateName' />
                    </LinkField> : <TextField source='StateName' className={classes.inlineBlock} />}
                    {Countries.roles.includes(permissions) ? <LinkField
                        source='CountryID'
                        reference='countries'
                        addLabel={true}
                        className={classes.inlineBlock}
                    >
                        <TextField source='CountryName' />
                    </LinkField> : <TextField source='CountryName' className={classes.inlineBlock} />}
                </GroupField>

                <GroupField>
                    {Districts.roles.includes(permissions) ? <LinkField
                        source='DistrictID'
                        reference='districts'
                        addLabel={true}
                        className={classes.inlineBlock}
                    >
                        <TextField source='DistrictName' />
                    </LinkField> : <TextField source='DistrictName' className={classes.inlineBlock} />}

                    {MultiDistricts.roles.includes(permissions) ? <LinkField
                        source='MultiDistrictID'
                        reference='multidistricts'
                        addLabel={true}
                        className={classes.inlineBlock}
                    >
                        <TextField source='MultiDistrictName' />
                    </LinkField> : <TextField source='MultiDistrictName' className={classes.inlineBlock} />}

                    {Partners.roles.includes(permissions) ? <LinkField
                        source='PartnerID'
                        reference='partners'
                        addLabel={true}
                        className={classes.inlineBlock}
                    >
                        <TextField source='PartnerName' />
                    </LinkField> : <TextField source='PartnerName' className={classes.inlineBlock} />}
                </GroupField>

                {Zones.roles.includes(permissions) ? <LinkField
                    source='ZoneID'
                    reference='zones'
                    addLabel={true}
                >
                    <TextField source='ZoneName' />
                </LinkField> : <TextField source='ZoneName' />}

                <ColoredBooleanField
                    source='Active'
                />
                <DateField
                    source='CreateDate'
                    showTime
                    className={classes.inlineBlock}
                />
                <DateField
                    source='ModifiedDate'
                    showTime
                    className={classes.inlineBlock}
                />
            </Tab>
            <Tab
                label='resources.clubs.tabs.members'
                path='members'
            >
                <ReferenceManyField
                    reference='members'
                    target='ClubID'
                    sort={{ field: 'LastName', order: 'ASC' }}
                    perPage={25}
                    addLabel={false}
                    pagination={<ShowPagination />}
                >
                    <Datagrid
                        rowClick='show'
                    >
                        <TextField
                            source='LastName'
                            headerClassName={classes.lastName}
                            cellClassName={classes.lastName}
                        />
                        <TextField
                            source='FirstName'
                            headerClassName={classes.firstName}
                            cellClassName={classes.firstName}
                        />
                        <TextField
                            source='Email'
                            headerClassName={classes.email}
                            cellClassName={classes.email}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
));

export default ClubsShow;