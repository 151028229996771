import React from 'react';
import { Filter, TextInput, NullableBooleanInput,} from 'react-admin';

import Clubs from '../Clubs';
import Districts from '../Districts';
import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';

const ListFilters = ({permissions, ...props}) => (
    <Filter {...props}>
        <TextInput
            source="LastName_like"
            label="resources.members.fields.LastName"
            resettable
            alwaysOn
        />
        <TextInput
            source="FirstName_like"
            label="resources.members.fields.FirstName"
            resettable
        />

        <TextInput
            source="Email_like"
            label="resources.members.fields.Email"
            resettable
        />

        {Clubs.roles.includes(permissions) && <TextInput
            source="ClubName_like"
            label="resources.members.fields.ClubName"
            resettable
        />}

        {Districts.roles.includes(permissions) && <TextInput
            source="DistrictName_like"
            label="resources.members.fields.DistrictName"
            resettable
        />}

        {MultiDistricts.roles.includes(permissions) && <TextInput
            source="MultiDistrictName_like"
            label="resources.members.fields.MultiDistrictName"
            resettable
        />}

        {Partners.roles.includes(permissions) && <TextInput
            source='PartnerName_like'
            label='resources.members.fields.PartnerName'
            resettable
        />}

        <TextInput
            source='ExternalID_like'
            label='resources.members.fields.ExternalID'
            resettable
        />

        <NullableBooleanInput
            source="Active"
            label="resources.members.fields.Active"
            alwaysOn
        />
    </Filter>
);

export default ListFilters;