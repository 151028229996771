import React from 'react';
import { translate } from 'react-admin';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import DashboardCard from './DashboardCard';

import Clubs from '../../pages/Clubs';
import Districts from '../../pages/Districts';
import MultiDistricts from '../../pages/MultiDistricts';
import Partners from '../../pages/Partners';
import Zones from '../../pages/Zones';

import styles from '../../styles/dashboardStyles';

import blue from '@material-ui/core/colors/blue';
import cyan from '@material-ui/core/colors/cyan';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';

const colorIndex = 'A700';

const items = [
    {name: Clubs.name, icon: Clubs.Icon, color: blue[colorIndex], roles: Clubs.roles},
    {name: Districts.name, icon: Districts.Icon, color: cyan[colorIndex], roles: Districts.roles},
    {name: MultiDistricts.name, icon: MultiDistricts.Icon, color: green[colorIndex], roles: MultiDistricts.roles},
    {name: Partners.name, icon: Partners.Icon, color: orange[colorIndex], roles: Partners.roles},
    {name: Zones.name, icon: Zones.Icon, color: red[colorIndex], roles: Zones.roles},
];

const GroupCard = translate(({ translate, permissions }) => (
    <Paper style={ styles.paper }>
        <Typography variant='title'>
            { translate('nayen.dashboard.groups.areas') }
        </Typography>
        {items.map(item => {
            return item.roles.includes(permissions) && <DashboardCard key={item.name} item={item} />;
        })}
    </Paper>
));

export default GroupCard;