import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';

const Title = ({ translate, record = {} }) => (
    <span >
        { translate('resources.trainings.name', { smart_count: 1 }) }: { `${record.FirstName} ${record.LastName} - ${record.ClassName}` }
    </span>
);

Title.propTypes = {
    translate: PropTypes.func,
    record: PropTypes.object,
};

export default translate(Title);