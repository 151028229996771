import {
    AUTH_GET_PERMISSIONS,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_ERROR,
    AUTH_CHECK,
} from 'react-admin';
import decodeJwt from 'jwt-decode';
import Members from '../pages/Members';
import gqlFetch from '../util/gqlFetch';

const tokenKey = 'auth_token';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        localStorage.removeItem(tokenKey);

        const { email, password } = params;
        const json = {
            query: 'mutation {login(email: "' + email + '", password: "' + password + '") {id token}}'
        };

        return gqlFetch(json)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject();
                }
                return response.json();
            })
            .then(json => {
                if (json) {
                    if (json.data && json.data.login) {
                        const token = json.data.login.token;
                        localStorage.setItem(tokenKey, token);
                        document.location.href = './';
                        return Promise.resolve();
                    }
                }
                return Promise.reject();
            });
    }

    if (type === AUTH_LOGOUT) {
        localStorage.removeItem(tokenKey);
        return Promise.resolve();
    }

    if (type === AUTH_ERROR) {
        const { status } = params;
        return (status === 401 || status === 403)
            ? Promise.reject()
            : Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        return localStorage.getItem(tokenKey) ? Promise.resolve() : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const token = localStorage.getItem(tokenKey);
        const decodedToken = decodeJwt(token);
        let role = 0;
        if (decodedToken && decodedToken.data) {
            role = decodedToken.data.role;
        }
        //role = 2; 2, 3, OR 7
        return (Members.roles.includes(role)) ? Promise.resolve(role) : Promise.reject();
    }

    return Promise.reject('Unknown method');
};