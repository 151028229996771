import React from 'react';
import { AppBar, UserMenu, MenuItemLink, translate } from 'react-admin';
import ProfileIcon from '../Profile/Icon';

const CustomUserMenu = translate(({translate, ...props}) => (
    <UserMenu {...props}>
        <MenuItemLink
            to="/profile"
            primaryText={translate('resources.profile.name')}
            leftIcon={<ProfileIcon />}
        />
    </UserMenu>
));

const CustomAppBar = props => <AppBar {...props} userMenu={<CustomUserMenu />} />;
export default CustomAppBar;