import React from 'react';
import PropTypes from 'prop-types';

const MenuIcon = ({ Icon }) => (
    <Icon />
);

MenuIcon.propTypes = {
    Icon: PropTypes.func,
};

export default MenuIcon;