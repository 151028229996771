import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { MenuItemLink, DashboardMenuItem, getResources, translate } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import MenuIcon from './MenuIcon';

const menuStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%'
};

const styles = {
    root: {}, // Style applied to the MenuItem from material-ui
    active: { backgroundColor: 'rgba(0, 0, 0, 0.12)', fontWeight: 'bold' }, // Style applied when the menu item is the active one
    icon: {}, // Style applied to the icon
};

const CustomMenu = ({ classes, resources, onMenuClick, translate, }) => {
    return (
        <div style={menuStyle}>
            <DashboardMenuItem
                key='home'
                primaryText={translate('nayen.dashboard.title')}
                classes={classes}
                onClick={onMenuClick}
            />
            <Divider />
            {resources.map(item => (
                item.name && item.icon && item.hasList &&
                    <Fragment
                        key={`${item.name}`}
                    >
                        <MenuItemLink
                            to={`/${item.name}`}
                            primaryText={translate(`resources.${item.name}.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={<MenuIcon Icon={item.icon} />}
                            classes={classes}
                            onClick={onMenuClick}
                        />
                        {(item.name === 'trainings' || item.name === 'zones') && <Divider />}
                    </Fragment>
            ))}
        </div>
    );
};

CustomMenu.propTypes = {
    classes: PropTypes.object,
    resources: PropTypes.array,
    onMenuClick:PropTypes.func,
    translate: PropTypes.func.isRequired,
};

const enhance = compose(
    withRouter,
    connect(
        state => ({
            resources: getResources(state),
            theme: state.theme,
            locale: state.i18n.locale,
        }),
        {}
    ),
    translate
);

export default enhance((withStyles(styles)(CustomMenu)));