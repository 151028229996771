import React from 'react';
import {
    List, Datagrid,
    TextField, NumberField, DateField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import styles from '../../styles/listStyles';

import { ColoredBooleanField } from '../../components/fields';

const CoursesList = withStyles(styles)(({classes, ...props}) => (
    <List
        actions={null}
        perPage={ 25 }
        bulkActionButtons={ false }
        sort={{ field: 'ClassName', order: 'ASC' }}
        filter={{ Active: true }}
        { ...props }
    >
        <Datagrid
            rowClick='show'
        >
            <NumberField
                source='id'
            />
            <TextField
                source='ClassName'
            />
            <NumberField
                source='PassingScore'
                headerClassName={classes.passingScore}
                cellClassName={classes.passingScore}
            />
            <ColoredBooleanField
                source='Active'
                headerClassName={classes.active}
                cellClassName={classes.active}
            />
            <DateField
                source='CreateDate'
                headerClassName={classes.createDate}
                cellClassName={classes.createDate}
            />
            <DateField
                source='ModifiedDate'
                headerClassName={classes.modifiedDate}
                cellClassName={classes.modifiedDate}
            />
        </Datagrid>
    </List>
));
export default CoursesList;