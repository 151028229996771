import React from 'react';
import {
    List, Datagrid,
    TextField, DateField,
    Pagination,
} from 'react-admin';
import ListActions from '../../components/actions/ListActions';
import { ColoredBooleanField } from '../../components/fields';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/listStyles';

import Clubs from '../Clubs';
import Districts from '../Districts';
import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';

import exporter from './exporter';
import ListFilters from './ListFilters';

const MembersPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100, 250, 500, 1000]} {...props} />;

const MembersList = withStyles(styles)(({permissions, classes, ...props}) => (
    <List
        actions={<ListActions permissions={permissions} />}
        pagination={<MembersPagination />}
        perPage={ 50 }
        bulkActionButtons={ false }
        filters={<ListFilters permissions={permissions} />}
        filterDefaultValues={{ Active: true }}
        sort={{ field: 'LastName', order: 'ASC' }}
        exporter={exporter}
        { ...props }
    >
        <Datagrid
            rowClick='show'
        >
            <TextField
                source='LastName'
                headerClassName={classes.lastName}
                cellClassName={classes.lastName}
            />
            <TextField
                source='FirstName'
                headerClassName={classes.firstName}
                cellClassName={classes.firstName}
            />
            <TextField
                source='Email'
                headerClassName={classes.email}
                cellClassName={classes.email}
            />

            {Clubs.roles.includes(permissions) && <TextField
                source='ClubName'
                headerClassName={classes.clubName}
                cellClassName={classes.clubName}
            />}

            {Districts.roles.includes(permissions) && <TextField
                source='DistrictName'
                headerClassName={classes.districtName}
                cellClassName={classes.districtName}
            />}

            {MultiDistricts.roles.includes(permissions) && <TextField
                source='MultiDistrictName'
                headerClassName={classes.multiDistrictName}
                cellClassName={classes.multiDistrictName}
            />}

            {Partners.roles.includes(permissions) && <TextField
                source='PartnerName'
                headerClassName={classes.partnerName}
                cellClassName={classes.partnerName}
            />}

            <ColoredBooleanField
                source='Active'
                headerClassName={classes.active}
                cellClassName={classes.active}
            />

            <DateField
                source='CreateDate'
                headerClassName={classes.createDate}
                cellClassName={classes.createDate}
            />
            <DateField
                source='ModifiedDate'
                headerClassName={classes.modifiedDate}
                cellClassName={classes.modifiedDate}
            />
        </Datagrid>
    </List>
));

export default MembersList;