import { downloadCSV } from 'react-admin';
import { unparse } from 'papaparse/papaparse.min';

const name = 'trainings';
const fields = ['MemberID', 'ExternalID', 'FirstName', 'LastName', 'ClubName', 'DistrictName','MultiDistrictName', 'PartnerName', 'ClassTypeID', 'ClassName','Score','TrainedDate', 'RequestDate'];

export default records => {
    const recordsForExport = records.map(record => {
        if (record.TrainedDt) {
            record.TrainedDate = new Date(record.TrainedDt).toLocaleDateString();
        }
        if (record.RequestDate) {
            record.RequestDate = new Date(record.RequestDate).toLocaleDateString();
        }
        return record;
    });
    const csv = unparse({
        data: recordsForExport,
        fields: fields, // order fields in the export
    });
    downloadCSV(csv, name); // download as csv file
};
