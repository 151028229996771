import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import gqlFetch from '../../util/gqlFetch';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import LoginIcon from '@material-ui/icons/ExitToApp';
import CancelIcon from '@material-ui/icons/Cancel';
import EmailIcon from '@material-ui/icons/Email';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { withStyles } from '@material-ui/core/styles';

import {
    Notification,
    translate,
    userLogin,
    showNotification as showNotificationAction,
} from 'react-admin';

const styles = (theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(https://source.unsplash.com/1600x900?landscape)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        width: '300px',
        maxWidth: '300px',
        marginTop: '5vh',
    },
    loginTitle: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        justifyContent: 'center',
        paddingLeft: '1em',
        paddingRight: '1em',
    },
    logo: {
        height: '8em',
        width: '8em',
    },
    avatar: {
        margin: '0.75em',
        display: 'flex',
        justifyContent: 'center',
    },
    buttonIcon: {
        marginRight: '0.25em',
    },
    loginHint: {
        padding: '0.75em 1em 0 1em',
        maxWidth: '300px',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
        height: '140px',
    },
    input: {
        marginTop: '0.5em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },

    forgotPasswordLink: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        float: 'right',
        padding: '0.5em 1em 0 0',
    },

    forgotPasswordHint: {
        color: theme.palette.grey[500],
    },

    forgotPasswordDialog: {
        margin: '10px',
        maxWidth: '300px',
        paddingBottom: '20px',
    },
    forgotPasswordDialogActions: {
        padding: '0 1em 0 0',
    },
});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

class Login extends Component {
    state = {
        showForgotPasswordDialog: false,
    }

    handleForgotPasswordClick = (event) => {
        event.preventDefault();
        this.setState({ showForgotPasswordDialog: true });
    };

    handleCloseForgotPassword = (event) => {
        event.preventDefault();
        this.setState({ showForgotPasswordDialog: false });
    };

    handleSendPassword = (event) => {
        event.preventDefault();

        const { showNotification } = this.props;
        const email = this.refs.forgotPasswordEmail.value;
        if (!email) {
            showNotification('nayen.login.missingEmail', 'warning');
            return;
        }
        const mutation = 'forgotPassword';
        const json = {
            query: 'mutation {' + mutation + '(email: "' + email + '")}'
        };

        return gqlFetch(json)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                if (response && response.data && (response.data[mutation] === true)) {
                    showNotification('nayen.forgotPassword.passwordReset');
                    this.setState({ showForgotPasswordDialog: false });
                } else {
                    showNotification('nayen.forgotPassword.passwordResetError', 'warning')
                }
            })
            .catch((e) => {
                console.error(e);
                showNotification('nayen.forgotPassword.passwordResetError', 'warning')
            });
    }

    handleLogin = (event) => {
        event.preventDefault();

        const { userLogin, showNotification } = this.props;
        const values = {
            email: this.refs.email.value,
            password: this.refs.password.value
        }
        if (!values.email) {
            showNotification('nayen.login.missingEmail', 'warning');
            return;
        } else if (!values.password) {
            showNotification('nayen.login.missingPassword', 'warning');
            return;
        }

        userLogin(
            values,
            this.props.location.state
                ? this.props.location.state.nextPathname
                : '/'
        );
    }

    render() {
        const { showForgotPasswordDialog, } = this.state;
        const { classes, isLoading, translate } = this.props;
        return (
            <div className={classes.main}>
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <Typography
                            className={classes.logo}
                            component='img'
                            src={ translate('nayen.dashboard.welcome.logoUrl') }
                        />
                    </div>
                    <Typography
                        className={classes.loginTitle}
                    >
                        { translate('nayen.title') }
                    </Typography>
                    <Typography
                        className={classes.loginHint}
                    >
                        { translate('nayen.login.instructions') }
                    </Typography>
                    <form onSubmit={this.handleLogin}>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    name="email"
                                    ref="email"
                                    component={renderInput}
                                    label={translate('nayen.login.email')}
                                    disabled={isLoading}
                                    autoFocus
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="password"
                                    ref="password"
                                    component={renderInput}
                                    label={translate('nayen.login.password')}
                                    type="password"
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <span
                            className={classes.forgotPasswordLink}
                            onClick={this.handleForgotPasswordClick}
                        >
                            {translate('nayen.forgotPassword.prompt')}
                        </span>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={isLoading}
                                className={classes.button}
                            >
                                {isLoading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                <LoginIcon
                                    className={classes.buttonIcon}
                                />
                                {translate('ra.auth.sign_in')}
                            </Button>
                        </CardActions>
                    </form>
                </Card>
                <Dialog
                    data-testid="dialog-forgot-password"
                    classes={{ paperFullWidth: classes.forgotPasswordDialog }}
                    fullWidth
                    open={showForgotPasswordDialog}
                    onClose={this.handleCloseForgotPassword}
                    aria-label={translate('nayen.forgotPassword.prompt')}
                >
                    <DialogTitle>
                        {translate('nayen.forgotPassword.prompt')}
                    </DialogTitle>
                    <form onSubmit={this.handleSendPassword} >
                        <DialogContent>
                            <Typography
                                className={classes.forgotPasswordHint}
                            >
                                { translate('nayen.forgotPassword.instructions') }
                            </Typography>
                            <div className={classes.input}>
                                <Field
                                    name="forgotPasswordEmail"
                                    ref="forgotPasswordEmail"
                                    component={renderInput}
                                    label={translate('nayen.login.email')}
                                    autoFocus
                                />
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.forgotPasswordDialogActions}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{marginRight: '1em'}}
                            >
                                {isLoading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                <EmailIcon
                                    className={classes.buttonIcon}
                                />
                                {translate('nayen.forgotPassword.sendPassword')}
                            </Button>
                            <Button
                                data-testid="button-close-modal"
                                variant="contained"
                                color="primary"
                                onClick={this.handleCloseForgotPassword}
                            >
                                <CancelIcon
                                    className={classes.buttonIcon}
                                />
                                {translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <Notification />
            </div>
        );
    }
}

Login.propTypes = {
    ...propTypes,
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    previousRoute: PropTypes.string,
    translate: PropTypes.func.isRequired,
    userLogin: PropTypes.func.isRequired,
    showNotification: PropTypes.func,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
    translate,
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.email) {
                errors.email = translate('ra.validation.required');
            }
            if (!values.password) {
                errors.password = translate('ra.validation.required');
            }
            if (!values.forgotPasswordEmail) {
                errors.forgotPasswordEmail = translate('ra.validation.required');
            } else {
            }
            return errors;
        },
    }),
    connect(mapStateToProps, {
        userLogin,
        showNotification: showNotificationAction,
    }),
    withStyles(styles)
);

export default enhance(Login);