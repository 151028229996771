import React from 'react';
import {
    Create, SimpleForm,
    TextInput, BooleanInput
} from 'react-admin';

import { required } from '../../util/fieldValidation';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const CountriesCreate = withStyles(styles)(({ classes,...props }) => (
    <Create {...props}>
        <SimpleForm submitOnEnter={false} >
            <TextInput
                source='CountryName'
                validate={[required()]}
            />
            <TextInput
                source='CountryCode'
                validate={[required()]}
                formClassName={classes.inlineBlock}
            />
            <BooleanInput
                source='Active'
                defaultValue={true}
            />
        </SimpleForm>
    </Create>
));
export default CountriesCreate;