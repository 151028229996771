import React from 'react';
import PropTypes from 'prop-types';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

const ListActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    resource,
    hideCreate,
    showFilter,
}) => {
    return (
        <CardActions>
            { filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            }) }
            {!hideCreate && <CreateButton
                basePath={basePath}
            />}
            { (exporter) && <ExportButton
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                maxResults={10000}
                exporter={exporter}
            />}
        </CardActions>
    );
};

ListActions.propTypes = {
    basePath: PropTypes.string,
    currentSort: PropTypes.object,
    displayedFilters: PropTypes.object,
    exporter: PropTypes.func,
    filters: PropTypes.object,
    filterValues: PropTypes.object,
    resource: PropTypes.string,
    hideCreate: PropTypes.bool,
    showFilter: PropTypes.func,
};

export default ListActions;