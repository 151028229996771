import React from 'react';
import {
    List, Datagrid,
    TextField, DateField,
} from 'react-admin';
import ListActions from '../../components/actions/ListActions';
import { ColoredBooleanField } from '../../components/fields';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/listStyles';

import MultiDistricts from '../MultiDistricts';
import Partners from '../Partners';
import Zones from '../Zones';

import ListFilters from './ListFilters';

const DistrictList = withStyles(styles)(({permissions, classes, ...props}) => (
    <List
        actions={<ListActions permissions={permissions} />}
        perPage={ 25 }
        bulkActionButtons={ false }
        filters={<ListFilters permissions={permissions} />}
        filterDefaultValues={{ Active: true }}
        sort={{
            field: 'DistrictName',
            order: 'ASC'
        }}
        { ...props }
    >
        <Datagrid
            rowClick='show'
        >
            <TextField
                source='DistrictName'
            />
            {MultiDistricts.roles.includes(permissions) && <TextField
                source='MultiDistrictName'
                headerClassName={classes.multiDistrictName}
                cellClassName={classes.multiDistrictName}
            />}
            {Partners.roles.includes(permissions) && <TextField
                source='PartnerName'
                headerClassName={classes.partnerName}
                cellClassName={classes.partnerName}
            />}
            {Zones.roles.includes(permissions) && <TextField
                source='ZoneName'
                headerClassName={classes.zoneName}
                cellClassName={classes.zoneName}
            />}
            <ColoredBooleanField
                source='Active'
                headerClassName={classes.active}
                cellClassName={classes.active}
            />
            <DateField
                source='CreateDate'
                headerClassName={classes.createDate}
                cellClassName={classes.createDate}
            />
            <DateField
                source='ModifiedDate'
                headerClassName={classes.modifiedDate}
                cellClassName={classes.modifiedDate}
            />
        </Datagrid>
    </List>
));
export default DistrictList;