import React from 'react';
import {
    Edit, SimpleForm,
    TextInput, ReferenceInput, AutocompleteInput, BooleanInput, TextField, DateField,
} from 'react-admin';
import Toolbar from '../../components/toolbars/EditToolbar';
import Title from './Title';

import { required } from '../../util/fieldValidation';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const MembersEdit = withStyles(styles)(({ classes,...props }) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<Toolbar />} submitOnEnter={false} >
            <TextInput
                source='FirstName'
                validate={[required()]}
                formClassName={classes.inlineBlock}
            />
            <TextInput
                source='LastName'
                validate={[required()]}
                formClassName={classes.inlineBlock}
            />
            <TextInput
                source='Email'
                type="email"
                validate={[required()]}
                validation={{ email: true }}
                style={{ width: 500 }}
            />
            <ReferenceInput
                source='ClubID'
                reference='clubs'
                validate={[required()]}
                sort={{ field: 'ClubName', order: 'ASC' }}
                perPage={1000}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ ClubName_like: searchText })}
            >
                <AutocompleteInput
                    optionText='ClubName'
                />
            </ReferenceInput>
            <TextInput
                source='ExternalID'
                formClassName={classes.inlineBlock}
            />
            <BooleanInput
                source='Active'
            />
            <TextField
                source='id'
                formClassName={classes.inlineBlock}
            />
            <DateField
                source='CreateDate'
                showTime
                formClassName={classes.inlineBlock}
            />
            <DateField
                source='ModifiedDate'
                showTime
                formClassName={classes.inlineBlock}
            />
        </SimpleForm>
    </Edit>
));
export default MembersEdit;