import grey from '@material-ui/core/colors/grey';
import defaultTheme from './defaultTheme';

const styles = {
    paper: {
        'marginBottom': '2em',
        'padding': '1em'
    },
    icon: {
        'color': defaultTheme.palette.common.white,
        'height': '2.3em',
        'width': '2.3em',
    },
    card: {
        'border': grey[500] + ' solid 1px',
        'borderRadius': '0.25em',
        'cursor': 'pointer',
        'display': 'inline-block',
        'height': '5em',
        'marginBottom': 0,
        'marginLeft': 0,
        'marginRight': '1em',
        'marginTop': '0.75em',
        'maxHeight': '5em',
        'maxWidth': '5em',
        'overflow': 'hidden',
        'padding': '0.25em',
        'textAlign': 'center',
        'width': '5em',
    },
    title: {
        'color': defaultTheme.palette.common.white,
        'fontSize': '0.75em',
    }
};

export default styles;