import React from 'react';
import {
    Edit, SimpleForm, DateField, EmailField, TextField, FunctionField, BooleanInput,
} from 'react-admin';

import { LinkField, } from '../../components/fields';

import Toolbar from '../../components/toolbars/EditToolbar';
import Title from './Title';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const UserEdit = withStyles(styles)(({ classes, ...props }) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<Toolbar />} submitOnEnter={false} >
            <LinkField
                source='MemberID'
                reference='members'
                addLabel={true}
                formClassName={classes.inlineBlock}
            >
                <FunctionField
                    render={(record) => (
                        `${record.FirstName} ${record.LastName}`
                    )}
                />
            </LinkField>
            <EmailField
                source='Email'
                formClassName={classes.inlineBlock}
            />
            <LinkField
                source='RoleID'
                reference='roles'
                addLabel={true}
            >
                <TextField source='RoleName' />
            </LinkField>

            <BooleanInput
                source='Active'
            />
            <DateField
                source='FirstLogin'
                showTime
                formClassName={classes.inlineBlock}
            />
            <DateField
                source='LastLogin'
                showTime
                formClassName={classes.inlineBlock}
            />
            <DateField
                source='LastPwdChange'
                showTime
                formClassName={classes.inlineBlock}
            />
            <DateField
                source='NextPwdChange'
                formClassName={classes.inlineBlock}
            />
        </SimpleForm>
    </Edit>
));
export default UserEdit;