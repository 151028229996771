import React from 'react';
import {
    List, Datagrid,
    TextField, DateField,
} from 'react-admin';
import { ColoredBooleanField } from '../../components/fields';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/listStyles';

const PartnersList = withStyles(styles)(({classes, ...props}) => (
    <List
        actions={null}
        perPage={ 25 }
        bulkActionButtons={ false }
        sort={{ field: 'PartnerName', order: 'ASC' }}
        filter={{ Active: true }}
        { ...props }
    >
        <Datagrid
            rowClick='show'
        >
            <TextField
                source='PartnerName'
            />
            <TextField
                source='ContactName'
                headerClassName={classes.contactName}
                cellClassName={classes.contactName}
            />
            <TextField
                source='ContactEMail'
                headerClassName={classes.email}
                cellClassName={classes.email}
            />
            <ColoredBooleanField
                source='Active'
                headerClassName={classes.active}
                cellClassName={classes.active}
            />
            <DateField
                source='CreateDate'
                headerClassName={classes.createDate}
                cellClassName={classes.createDate}
            />
            <DateField
                source='ModifiedDate'
                headerClassName={classes.modifiedDate}
                cellClassName={classes.modifiedDate}
            />
        </Datagrid>
    </List>
));
export default PartnersList;