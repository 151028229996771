import React from 'react';
import {
    Create, SimpleForm,
    ReferenceInput, AutocompleteInput
} from 'react-admin';

import { required } from '../../util/fieldValidation';
import { parse } from 'query-string';

const DistrictClassesCreate = (props) => {
    // Read the districtid from the location which is injected by React Router and passed to our component by react-admin automatically
    const { districtid: district_id_string } = parse(props.location.search);
    const DistrictID = district_id_string ? parseInt(district_id_string, 10) : 0;
    const redirect = DistrictID ? `/districts/${DistrictID}/show/courses` : false;

    return (
        <Create {...props}>
            <SimpleForm
                submitOnEnter={false}
                defaultValue={{DistrictID}}
                redirect={redirect}
                style={{ marginBottom: '200px' }}
            >
                <ReferenceInput
                    source='DistrictID'
                    reference='districts'
                    validate={[required()]}
                    perPage={1}
                    filter={{ Active: true, ids: [DistrictID] }}
                >
                    <AutocompleteInput optionText='DistrictName' />
                </ReferenceInput>
                <ReferenceInput
                    source='ClassTypeID'
                    reference='courses'
                    validate={[required()]}
                    sort={{ field: 'ClassName', order: 'ASC' }}
                    perPage={1000}
                    filter={{ Active: true }}
                    filterToQuery={searchText => ({ ClassName_like: searchText })}
                >
                    <AutocompleteInput optionText='ClassName' />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export default DistrictClassesCreate;