import React from 'react';
import {
    Edit, SimpleForm,
    TextInput, BooleanInput, ReferenceInput, AutocompleteInput, DateField,
} from 'react-admin';
import Toolbar from '../../components/toolbars/EditToolbar';
import Title from './Title';

import { required } from '../../util/fieldValidation';

import Districts from '../Districts';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const ClubsEdit = withStyles(styles)(({ permissions, classes,...props }) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm toolbar={<Toolbar />} submitOnEnter={false} >
            <TextInput
                source='ClubName'
                validate={[required()]}
            />
            {Districts.roles.includes(permissions) && <ReferenceInput
                source='DistrictID'
                reference='districts'
                validate={[required()]}
                sort={{ field: 'DistrictName', order: 'ASC' }}
                perPage={1000}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ DistrictName_like: searchText })}
            >
                <AutocompleteInput optionText='DistrictName' />
            </ReferenceInput>}

            <ReferenceInput
                source='StateID'
                reference='states'
                validate={[required()]}
                sort={{ field: 'StateName', order: 'ASC' }}
                perPage={1000}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ StateName_like: searchText })}
                formClassName={classes.inlineBlock}
            >
                <AutocompleteInput optionText='StateName' />
            </ReferenceInput>
            <ReferenceInput
                source='CountryID'
                reference='countries'
                validate={[required()]}
                sort={{ field: 'CountryName', order: 'ASC' }}
                perPage={1000}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ CountryName_like: searchText })}
                formClassName={classes.inlineBlock}
            >
                <AutocompleteInput optionText='CountryName' />
            </ReferenceInput>
            <BooleanInput
                source='Active'
            />
            <DateField
                source='CreateDate'
                showTime
                formClassName={classes.inlineBlock}
            />
            <DateField
                source='ModifiedDate'
                showTime
                formClassName={classes.inlineBlock}
            />
        </SimpleForm>
    </Edit>
));
export default ClubsEdit;