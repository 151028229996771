import reactAdmin from './reactAdmin';
import domain from './domain';

export const en = {
    'name': 'English',
    'messages': {
        ...reactAdmin.en,
        ...domain.en,
    }
};

export const es = {
    'name': 'Español',
    'messages': {
        ...reactAdmin.es,
        ...domain.es,
    }
};

export const fr = {
    'name': 'Français',
    'messages': {
        ...reactAdmin.fr,
        ...domain.fr,
    }
};

export const pt = {
    'name': 'Português',
    'messages': {
        ...reactAdmin.pt,
        ...domain.pt,
    },
};

export const locales = {
    'en': en,
    'es': es,
    'fr': fr,
    'pt': pt,
};

export default locales;