import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { change, submit, isSubmitting } from 'redux-form';
import {
    fetchEnd, fetchStart, refreshView, required, showNotification,
    translate,
    Button, SimpleForm, ReferenceInput, SelectInput, FunctionField,
    UPDATE, CREATE, GET_LIST,
} from 'react-admin';

import gqlFetch from '../../util/gqlFetch';

import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import dataProvider from '../../dataProvider';

import RoleIcon from '../../pages/Roles/Icon';

class AssignRoleButton extends Component {

    state = {
        error: false,
        showDialog: false,
        user: {},
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are outside the form
        submit('member-assign-user-role');
    };

    handleSubmit = (values) => {
        const { user } = this.state;
        const { record, fetchStart, showNotification } = this.props;

        const UserID = user.id;
        const RoleRecordID = user.RoleRecordID;
        const MemberID = record.id;
        const Email = record.Email;
        const RoleID = values.RoleID;

        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();

        // As we want to know when the new post has been created in order
        // to close the modal, we use the dataProvider directly
        dataProvider().then(provider => {
            // update existing
            if (UserID && RoleRecordID) {
                provider(UPDATE, 'userroles', { id: RoleRecordID, data: {
                    UserID: UserID,
                    RoleID: RoleID,
                } })
                .catch(error => {
                    showNotification(error.message, 'error');
                })
                .finally(() => {
                    this.updateCompleted();
                });
            } else {
                // User already exists but not assigned role.
                // Should not happen but just in case...
                if (UserID) {
                    provider(CREATE, 'userroles', { data: {
                        UserID: UserID,
                        RoleID: RoleID,
                    }})
                    .catch(error => {
                        showNotification(error.message, 'error');
                    })
                    .finally(() => {
                        this.updateCompleted();
                    });

                // First create the user, assign them the role and then send them a new password
                } else {
                    provider(CREATE, 'users', { data: {
                        MemberID: MemberID,
                        Active: true,
                    }})
                    .then((response) => {
                        provider(CREATE, 'userroles', { data: {
                            UserID: response.data.id,
                            RoleID: RoleID
                        }})
                        .then(() => {
                            // Send them a new password
                            const json = {
                                query: 'mutation {forgotPassword(email: "' + Email + '")}'
                            };
                            gqlFetch(json).then(response => {
                                if (response.status < 200 || response.status >= 300) {
                                    throw new Error(response.statusText);
                                }
                            })
                        })
                    })
                    .catch((error) => {
                        showNotification(error.message, 'error');
                    })
                    .finally(() => {
                        this.updateCompleted();
                    });
                }

            }
        });
    };

    updateCompleted = function () {
        const { fetchEnd, showNotification, } = this.props;
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
        this.setState({ showDialog: false });
        refreshView();
        showNotification('resources.users.assignRole.roleAssigned');
    }

    componentDidMount() {
        const { record } = this.props;
        dataProvider().then(provider => {
            provider(GET_LIST, 'users', {
                filter: { MemberID: record.id },
                sort: { field: 'MemberID', order: 'ASC' },
                pagination: { page: 1, perPage: 1 },
            }).then(response => {
                response.data && response.data.length && this.setState({
                    user: response.data[0]
                })
            });
        });
    }

    render() {
        const { user, showDialog } = this.state;
        const { record, translate } = this.props;
        return (
            <Fragment>
                <Button
                    onClick={this.handleClick}
                    label='resources.users.assignRole.buttonLabel'
                    variant="contained"
                    style={{float:'right', margin:'40px 20px 20px 0'}}
                >
                    <RoleIcon />
                </Button>
                <Dialog
                    fullWidth
                    disableEnforceFocus
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label={ translate('resources.users.assignRole.dialogTitle') }
                >
                    <DialogTitle>{ translate('resources.users.assignRole.dialogTitle') }</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            // We override the redux-form name to avoid collision with the react-admin main form
                            form="member-assign-user-role"
                            resource="users"
                            // We override the redux-form onSubmit prop to handle the submission ourselves
                            onSubmit={this.handleSubmit}
                            // We want no toolbar at all as we have our modal actions
                            toolbar={null}
                        >
                            <FunctionField
                                label='resources.members.fields.FullName'
                                render={() => (
                                    `${record.FirstName} ${record.LastName}`
                                )}
                            />
                            <ReferenceInput
                                record={record}
                                source='RoleID'
                                reference='roles'
                                validate={required()}
                                sort={{ field: 'SortOrder', order: 'ASC' }}
                                filter={{ Active: true}}
                                perPage={100}
                                defaultValue={user.RoleID}
                            >
                                <SelectInput
                                    optionText='Description'
                                />
                            </ReferenceInput>
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            label="ra.action.save"
                            onClick={this.handleSaveClick}
                            variant='contained'
                        >
                            <RoleIcon />
                        </Button>
                        <Button
                            label="ra.action.cancel"
                            onClick={this.handleCloseClick}
                            variant='contained'
                        >
                            <CancelIcon />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

AssignRoleButton.propTypes = {
    translate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('member-assign-user-role')(state)
});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

const enhance = compose(
    translate,
)

export default enhance(connect(mapStateToProps, mapDispatchToProps)(
    AssignRoleButton
));

