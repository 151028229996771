import React from 'react';
import {
    Show, SimpleShowLayout,
    TextField, DateField, EmailField, FunctionField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { SendNewPasswordButton } from '../../components/buttons';

import styles from '../../styles/showStyles';

import { ColoredBooleanField, GroupField, LinkField, } from '../../components/fields';
import Title from './Title';

const UsersShow = withStyles(styles)(({ classes,...props }) => (
    <Show title={<Title />} {...props}>
        <SimpleShowLayout>
            <SendNewPasswordButton />
            <LinkField
                source='MemberID'
                reference='members'
                addLabel={true}
                className={classes.inlineBlock}
            >
                <FunctionField
                    render={(record) => (
                        `${record.FirstName} ${record.LastName}`
                    )}
                />
            </LinkField>
            <EmailField
                source='Email'
                className={classes.inlineBlock}
            />
            <LinkField
                source='RoleID'
                reference='roles'
                addLabel={true}
            >
                <TextField source='RoleName' />
            </LinkField>

            <GroupField>
                <LinkField
                    source='ClubID'
                    reference='clubs'
                    addLabel={true}
                    className={classes.inlineBlock}
                >
                    <TextField source='ClubName' />
                </LinkField>

                <LinkField
                    source='DistrictID'
                    reference='districts'
                    addLabel={true}
                    className={classes.inlineBlock}
                >
                    <TextField source='DistrictName' />
                </LinkField>
            </GroupField>

            <GroupField>
                <LinkField
                    source='MultiDistrictID'
                    reference='multidistricts'
                    addLabel={true}
                    className={classes.inlineBlock}
                >
                    <TextField source='MultiDistrictName' />
                </LinkField>

                <LinkField
                    source='PartnerID'
                    addLabel={true}
                    className={classes.inlineBlock}
                >
                    <TextField source='PartnerName' />
                </LinkField>
            </GroupField>

            <LinkField
                source='ZoneID'
                reference='zones'
                addLabel={true}
            >
                <TextField source='ZoneName' />
            </LinkField>

            <ColoredBooleanField
                source='Active'
            />
            <GroupField>
                <DateField
                    source='FirstLogin'
                    showTime
                    className={classes.inlineBlock}
                />
                <DateField
                    source='LastLogin'
                    showTime
                    className={classes.inlineBlock}
                />
            </GroupField>
            <GroupField>
                <DateField
                    source='LastPwdChange'
                    showTime
                    className={classes.inlineBlock}
                />
                <DateField
                    source='NextPwdChange'
                    className={classes.inlineBlock}
                />
            </GroupField>
        </SimpleShowLayout>
    </Show>
));
export default UsersShow;