import React from 'react';
import { ChipField } from 'react-admin';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

import defaultTheme from '../../styles/defaultTheme';

const commonStyle = {
    alignItems: 'center',
    color: defaultTheme.palette.common.white,
    padding: 0,
    verticalAlign: 'middle',
};

const styles = {
    passed: {
        backgroundColor: green[800],
        ...commonStyle,
    },
    failed: {
        backgroundColor: red[800],
        ...commonStyle,
    }
};

const colored = WrappedComponent => {
    const component = (props) =>
        <WrappedComponent {...props} style={ styles[(props.record['Passed']) ? 'passed' : 'failed'] } />;

    component.displayName = `Colored(${WrappedComponent.displayName})`;

    return component;
};

const ColoredScoreField = colored(ChipField);

// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component):
ColoredScoreField.defaultProps = ChipField.defaultProps;

export default ColoredScoreField;