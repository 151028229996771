import React from 'react';
import { Filter, TextInput, NullableBooleanInput, } from 'react-admin';

const ListFilters = (props) => (
    <Filter {...props}>
        <TextInput
            source='LastName_like'
            label='resources.users.fields.LastName'
            alwaysOn
            resettable
        />
        <TextInput
            source='FirstName_like'
            label='resources.users.fields.FirstName'
            alwaysOn
            resettable
        />
        <TextInput
            source="Email_like"
            label="resources.users.fields.Email"
            resettable
        />
        <TextInput
            source='ClubName_like'
            label='resources.users.fields.ClubName'
            resettable
        />
        <TextInput
            source='DistrictName_like'
            label='resources.users.fields.DistrictName'
            resettable
        />
        <TextInput
            source='MultiDistrictName_like'
            label='resources.users.fields.MultiDistrictName'
            resettable
        />
        <TextInput
            source='PartnerName_like'
            label='resources.users.fields.PartnerName'
            resettable
        />
        <NullableBooleanInput
            source='Active'
            alwaysOn
        />
    </Filter>
);

export default ListFilters;