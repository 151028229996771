import 'babel-polyfill';
import React, { Component } from 'react';
import { Admin, Resource, resolveBrowserLocale } from 'react-admin';

import dataProviderFactory from './dataProvider';
import authProvider from './authProvider';
import appRoutes from './routes';
import appTheme from './styles/appTheme';

import AppLayout from './components/Layout';
import AppDashboard from './components/Dashboard';
import AppLogin from './components/Login';

import './App.css';

import Clubs from './pages/Clubs';
import Courses from './pages/Courses';
import Countries from './pages/Countries';
import Districts from './pages/Districts';
import DistrictCourses from './pages/DistrictCourses';
import Members from './pages/Members';
import MultiDistricts from './pages/MultiDistricts';
import Partners from './pages/Partners';
import PartnerCourses from './pages/PartnerCourses';
import Roles from './pages/Roles';
import States from './pages/States';
import Trainings from './pages/Trainings';
import Users from './pages/Users';
import Zones from './pages/Zones';

import i18nProvider from './i18n';

class App extends Component {
    constructor() {
        super();
        this.state = { dataProvider: null };
    }

    async componentDidMount() {
        const dataProvider = await dataProviderFactory();
        this.setState({ dataProvider });
    }

    render() {
        const { dataProvider } = this.state;

        if (!dataProvider) {
            return (
                <div className='loader-container'>
                    <div className='loader'>Loading...</div>
                </div>
            );
        }
        return (
            <Admin
                title='NAYEN Training Administration'
                dataProvider={dataProvider}
                authProvider={authProvider}
                appLayout={AppLayout}
                dashboard={AppDashboard}
                loginPage={AppLogin}
                theme={appTheme}
                customRoutes={appRoutes}
                locale={resolveBrowserLocale()}
                i18nProvider={i18nProvider}
            >
                {permissions => [
                    Members.roles.includes(permissions) &&
                        <Resource
                            name={Members.name}
                            icon={Members.Icon}
                            list={Members.List}
                            show={Members.Show}
                            edit={Members.Edit}
                            create={Members.Create}
                        />,

                    Trainings.roles.includes(permissions) &&
                        <Resource
                            name={Trainings.name}
                            icon={Trainings.Icon}
                            list={Trainings.List}
                            show={Trainings.Show}
                        />,

                    Clubs.roles.includes(permissions) &&
                        <Resource name={Clubs.name}
                            icon={Clubs.Icon}
                            list={Clubs.List}
                            show={Clubs.Show}
                            edit={Clubs.Edit}
                            create={Clubs.Create}
                        />,

                    Districts.roles.includes(permissions) &&
                        <Resource
                            name={Districts.name}
                            icon={Districts.Icon}
                            list={Districts.List}
                            show={Districts.Show}
                            edit={Districts.Edit}
                            create={Districts.Create}
                        />,

                    MultiDistricts.roles.includes(permissions) &&
                        <Resource
                            name={MultiDistricts.name}
                            icon={MultiDistricts.Icon}
                            list={MultiDistricts.List}
                            show={MultiDistricts.Show}
                            edit={MultiDistricts.Edit}
                            create={MultiDistricts.Create}
                        />,

                    Partners.roles.includes(permissions) &&
                        <Resource
                            name={Partners.name}
                            icon={Partners.Icon}
                            list={Partners.List}
                            show={Partners.Show}
                        />,

                    Zones.roles.includes(permissions) &&
                        <Resource
                            name={Zones.name}
                            icon={Zones.Icon}
                            list={Zones.List}
                            show={Zones.Show}
                            edit={Zones.Edit}
                            create={Zones.Create}
                        />,

                    Courses.roles.includes(permissions) &&
                        <Resource
                            name={Courses.name}
                            icon={Courses.Icon}
                            list={Courses.List}
                            show={Courses.Show}
                        />,

                    States.roles.includes(permissions) &&
                        <Resource
                            name={States.name}
                            icon={States.Icon}
                            list={States.List}
                            show={States.Show}
                            edit={States.Edit}
                            create={States.Create}
                        />,

                    Countries.roles.includes(permissions) &&
                        <Resource
                            name={Countries.name}
                            icon={Countries.Icon}
                            list={Countries.List}
                            show={Countries.Show}
                            edit={Countries.Edit}
                            create={Countries.Create}
                        />,

                    Users.roles.includes(permissions) &&
                        <Resource
                            name={Users.name}
                            icon={Users.Icon}
                            list={Users.List}
                            show={Users.Show}
                            edit={Users.Edit}
                        />,
                    Users.roles.includes(permissions) &&
                        <Resource name='userroles' />,

                    Roles.roles.includes(permissions) &&
                        <Resource
                            name={Roles.name}
                            icon={Roles.Icon}
                            list={Roles.List}
                            show={Roles.Show}
                        />,

                    DistrictCourses.roles.includes(permissions) &&
                        <Resource
                            name={DistrictCourses.name}
                            create={DistrictCourses.Create}
                        />,

                    PartnerCourses.roles.includes(permissions) &&
                        <Resource
                            name={PartnerCourses.name}
                            create={PartnerCourses.Create}
                        />,

                    Members.roles.includes(permissions) && Trainings.roles.includes(permissions) &&
                        <Resource name='viewmembertrainingrequests' />,

                    Members.roles.includes(permissions) && Trainings.roles.includes(permissions) &&
                        <Resource name='viewmembertrainingresults' />,

                    Members.roles.includes(permissions) && Trainings.roles.includes(permissions) &&
                        <Resource name='viewmembercourses' />,

                    Members.roles.includes(permissions) && Trainings.roles.includes(permissions) &&
                        <Resource name='membertrainingrequests' />,
                ]}
            </Admin>
        );
    }
}
export default App;