import React from 'react';
import {
    List, Datagrid,
    TextField, DateField,
    Pagination,
} from 'react-admin';
import ListActions from '../../components/actions/ListActions';
import { ColoredBooleanField } from '../../components/fields';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/listStyles';

import ListFilters from './ListFilters';

const UserPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100, 250, 500, 1000]} {...props} />;

const UsersList = withStyles(styles)(({permissions, classes, ...props}) => (
    <List
        actions={<ListActions permissions={permissions} hideCreate={true} />}
        pagination={<UserPagination />}
        perPage={ 50 }
        bulkActionButtons={ false }
        filters={<ListFilters permissions={permissions} />}
        filterDefaultValues={{ Active: true }}
        sort={{ field: 'LastName', order: 'ASC' }}
        { ...props }
    >
        <Datagrid
            rowClick='show'
        >
            <TextField
                source='LastName'
                headerClassName={classes.lastName}
                cellClassName={classes.lastName}
            />
            <TextField
                source='FirstName'
                headerClassName={classes.firstName}
                cellClassName={classes.firstName}
            />
            <TextField
                source='RoleName'
                headerClassName={classes.roleName}
                cellClassName={classes.roleName}
            />
            <TextField
                source='ClubName'
                headerClassName={classes.clubName}
                cellClassName={classes.clubName}
            />
            <TextField
                source='DistrictName'
                headerClassName={classes.districtName}
                cellClassName={classes.districtName}
            />
            <TextField
                source='MultiDistrictName'
                headerClassName={classes.multidistrictName}
                cellClassName={classes.multidistrictName}
            />
            <TextField
                source='PartnerName'
                headerClassName={classes.partnerName}
                cellClassName={classes.partnerName}
            />
            <ColoredBooleanField
                source='Active'
                headerClassName={classes.active}
                cellClassName={classes.active}
            />
            <DateField
                source='LastLogin'
                headerClassName={classes.lastLogin}
                cellClassName={classes.lastLogin}
            />
            <DateField
                source='LastPwdChange'
                headerClassName={classes.lastPwdChange}
                cellClassName={classes.lastPwdChange}
            />
        </Datagrid>
    </List>
));
export default UsersList;