import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';

const Title = ({ translate, record = {} }) => (
    <span>
        { translate('resources.districts.name', { smart_count: 1 }) }: { `${record.DistrictName}` }
    </span>
);

Title.propTypes = {
    translate: PropTypes.func,
    record: PropTypes.object,
};

export default translate(Title);