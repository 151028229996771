import React from 'react';
import {
    Create, SimpleForm,
    TextInput, BooleanInput, ReferenceInput, AutocompleteInput,
} from 'react-admin';

import { required } from '../../util/fieldValidation';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const StatesCreate = withStyles(styles)(({ classes,...props }) => (
    <Create {...props}>
        <SimpleForm submitOnEnter={false} >
            <TextInput
                source='StateName'
                validate={[required()]}
            />
            <TextInput
                source='StateCode'
                validate={[required()]}
            />
            <ReferenceInput
                source='CountryID'
                reference='countries'
                validate={[required()]}
                sort={{ field: 'CountryName', order: 'ASC' }}
                perPage={1000}
                filter={{ Active: true }}
                filterToQuery={searchText => ({ CountryName_like: searchText })}
                formClassName={classes.inlineBlock}
            >
                <AutocompleteInput optionText='CountryName' />
            </ReferenceInput>
            <BooleanInput
                source='Active'
                defaultValue={true}
            />
        </SimpleForm>
    </Create>
));
export default StatesCreate;