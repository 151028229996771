import React from 'react';
import {
    List, Datagrid,
    TextField, DateField,
} from 'react-admin';
import ListActions from '../../components/actions/ListActions';
import { ColoredBooleanField } from '../../components/fields';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/listStyles';

const StatesList = withStyles(styles)(({classes, ...props}) => (
    <List
        actions={<ListActions />}
        perPage={ 25 }
        bulkActionButtons={ false }
        sort={{ field: 'StateName', order: 'ASC' }}
        filter={{ Active: true }}
        { ...props }
    >
        <Datagrid
            rowClick='show'
        >
            <TextField
                source='StateName'
            />
            <TextField
                source='StateCode'
                headerClassName={classes.stateCode}
                cellClassName={classes.stateCode}
            />
            <TextField
                source='CountryName'
                headerClassName={classes.countryName}
                cellClassName={classes.countryName}
            />
            <ColoredBooleanField
                source='Active'
                headerClassName={classes.active}
                cellClassName={classes.active}
            />
            <DateField
                source='CreateDate'
                headerClassName={classes.createDate}
                cellClassName={classes.createDate}
            />
            <DateField
                source='ModifiedDate'
                headerClassName={classes.modifiedDate}
                cellClassName={classes.modifiedDate}
            />
        </Datagrid>
    </List>
));
export default StatesList;