import React from 'react';
import {
    Create, SimpleForm,
    TextInput, BooleanInput,
} from 'react-admin';

import { required } from '../../util/fieldValidation';

import { withStyles } from '@material-ui/core/styles';
import styles from '../../styles/showStyles';

const ZonesCreate = withStyles(styles)(({ classes,...props }) => (
    <Create {...props}>
        <SimpleForm submitOnEnter={false} >
            <TextInput
                source='ZoneName'
                validate={[required()]}
            />
            <TextInput
                source='ZoneNumber'
                formClassName={ classes.inlineBlock }
            />
            <BooleanInput
                source='Active'
                defaultValue={true}
            />
        </SimpleForm>
    </Create>
));
export default ZonesCreate;