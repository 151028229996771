import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, showNotification } from 'react-admin';
import PasswordIcon from '@material-ui/icons/VpnKey';

import gqlFetch from '../../util/gqlFetch';

class SendNewPasswordButton extends Component {

    handleSendPassword = (event) => {
        event.preventDefault();

        const {
            record,
            showNotification
        } = this.props;

        const email = record.Email;
        const mutation = 'forgotPassword';
        const json = {
            query: 'mutation {' + mutation + '(email: "' + email + '")}'
        };

        return gqlFetch(json)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                if (response && response.data && (response.data[mutation] === true)) {
                    showNotification('resources.users.sendNewPassword.passwordSent');
                } else {
                    showNotification('resources.users.sendNewPassword.passwordSendError', 'warning')
                }
            })
            .catch((e) => {
                console.error(e);
                showNotification('resources.users.sendNewPassword.passwordSendError', 'warning')
            });
    }

    render() {
        return (
            <Button
                onClick={this.handleSendPassword}
                label='resources.users.sendNewPassword.buttonLabel'
                variant="contained"
                style={{float:'right', margin:'40px 20px 20px 0'}}
                >
                <PasswordIcon />
            </Button>
        );
    }
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(
    SendNewPasswordButton
);