import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';
import CoursesIcon from '../../pages/Courses/Icon';

const styles = {
    button: {
        marginTop: '1em'
    }
};

const AddCourseButton = ({classes, to, record, label}) => (
    <Button
        className = {classes.button}
        component = {Link}
        to = {`${to}${record.id}`}
        label = {label}
        title = {label}
        variant = 'contained'
    >
        <CoursesIcon / >
    </Button>
);

AddCourseButton.propTypes = {
    classes: PropTypes.object,
    to: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
};

export default withStyles(styles)(AddCourseButton);